import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Select from 'react-select';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import customStyles from './css/customSelectStyles';
import paginationStyles from './css/paginationSelectStyles';

const DepartmentEditor = () => {
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState('');
  const [newDepartment, setNewDepartment] = useState({ name: '' });
  const [editDepartmentId, setEditDepartmentId] = useState(null);
  const [editDepartment, setEditDepartment] = useState({ name: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [loadingFaculties, setLoadingFaculties] = useState(true); // Состояние загрузки факультетов
  const [loadingDepartments, setLoadingDepartments] = useState(false); // Состояние загрузки кафедр
  const [searchActive, setSearchActive] = useState(false);
  const searchRef = useRef(null);
  const buttonRef = useRef(null);

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const fetchFaculties = useCallback(async () => {
    try {
      setLoadingFaculties(true);
      const response = await api.get('/faculty/all');
      setFaculties(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке факультетов', error);
    } finally {
      setLoadingFaculties(false);
    }
  }, []);

  useEffect(() => {
    fetchFaculties();
  }, [fetchFaculties]);

  const fetchDepartments = useCallback(async () => {
    try {
      setLoadingDepartments(true);
      let req = `/department/by-foreign-key/faculty_id/${selectedFacultyId}?page=${page}&limit=${limit}`;
      if (search) {
        req += `&searchFields=name&search=${search}`;
      }
      if (sort) req += `&sort=${sort}`;

      const response = await api.get(req);
      const data = response.data;
      setDepartments(data.data || []);
      setTotalPages(data.pagination ? data.pagination.totalPages : 1);
      if (page > (data.pagination ? data.pagination.totalPages : 1)) {
        setPage(1);
      }
    } catch (error) {
      console.error('Ошибка при загрузке кафедр', error);
      setDepartments([]);
      setTotalPages(1);
    } finally {
      setLoadingDepartments(false);
    }
  }, [selectedFacultyId, page, limit, search, sort]);

  useEffect(() => {
    if (selectedFacultyId) {
      fetchDepartments();
    }
  }, [selectedFacultyId, page, limit, search, fetchDepartments]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const addDepartment = async () => {
    try {
      await api.post('/department', { ...newDepartment, faculty_id: selectedFacultyId });
      toast.success('Кафедра добавлена!');
      fetchDepartments();
      setNewDepartment({ name: '' });
      setShowAddForm(false);
    } catch (error) {
      console.error('Ошибка при добавлении кафедры', error);
      toast.error('Ошибка при добавлении кафедры');
    }
  };

  const deleteDepartment = async (id) => {
    try {
      await api.delete(`/department/${id}`);
      toast.success('Кафедра удалена!');
      fetchDepartments();
    } catch (error) {
      console.error('Ошибка при удалении кафедры', error);
      toast.error('Ошибка при удалении кафедры');
    }
  };

  const startEditDepartment = (department) => {
    setEditDepartmentId(department.id);
    setEditDepartment({ name: department.name });
  };

  const cancelEditDepartment = () => {
    setEditDepartmentId(null);
    setEditDepartment({ name: '' });
  };

  const updateDepartment = async () => {
    try {
      await api.put(`/department/${editDepartmentId}`, { ...editDepartment, faculty_id: selectedFacultyId });
      toast.success('Кафедра обновлена!');
      fetchDepartments();
      cancelEditDepartment();
    } catch (error) {
      console.error('Ошибка при обновлении кафедры', error);
      toast.error('Ошибка при обновлении кафедры');
    }
  };

  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    searchElement.style.minWidth = '200px';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      searchElement.style.minWidth = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  const toggleAddForm = () => {
    setIsAnimating(true);

    if (!showAddForm) {
      setTimeout(() => {
        setShowAddForm(true);
        setIsAnimating(false);
      }, 150);
    } else {
      setIsClosing(true);
      setShowAddForm(false);
      setIsAnimating(false);
      setIsClosing(false);
    }
  };

  useEffect(() => {
    const updateModalPosition = () => {
      if (buttonRef.current && showAddForm) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const modalContent = document.querySelector('.editor-add-modal');

        if (modalContent) {
          modalContent.style.top = `${buttonRect.bottom}px`;
          modalContent.style.left = `${buttonRect.left}px`;
        }
      }
    };

    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    updateModalPosition();

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, [showAddForm]);

  useEffect(() => { if (!selectedFacultyId) setSearch(''); }, [selectedFacultyId]);

  return (
    <div className='container'>
      <div className='selects-wrapper'>
        <label className='select-label'>
          <span className='select-title'>Факультет</span>
          {loadingFaculties ? (
            <Skeleton height={38} width={200}/>
          ) : (
            <Select
              styles={customStyles}
              value={faculties.find(faculty => faculty.value === selectedFacultyId)}
              onChange={(selectedOption) => setSelectedFacultyId(selectedOption ? selectedOption.value : '')}
              options={faculties.map(faculty => ({ value: faculty.id, label: faculty.name }))}
              placeholder="Выберите факультет"
              noOptionsMessage={() => "Факультеты не найдены"}
              isClearable
            />
          )}
        </label>
      </div>
      {selectedFacultyId && (
        <>
          <div className='actions-list'>
            <button
              ref={buttonRef}
              onClick={toggleAddForm}
              className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
            >
              <span className="add-entity-button-text">
                <span className="text-add">Добавить кафедру</span>
                <span className="text-cancel">Отмена</span>
              </span>
              <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
            </button>
            <Modal
              isOpen={showAddForm}
              onRequestClose={toggleAddForm}
              contentLabel="Добавить кафедру"
              className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
              overlayClassName="editor-add-overlay"
              closeTimeoutMS={300}
              style={{
                content: {
                  top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
                  left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
                  transform: 'translate(0, 0)',
                },
              }}
            >
              <h3>Добавить кафедру</h3>
              <div className='editor-add-modal-form'>
                <div className='editor-add-modal-form-input-wrapper'>
                  <input
                    type="text"
                    placeholder="Название кафедры"
                    value={newDepartment.name}
                    onChange={(e) => setNewDepartment({ ...newDepartment, name: e.target.value })}
                  />
                  {newDepartment.name && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewDepartment({ ...newDepartment, name: '' })}><IonIcon icon={closeOutline} /></button>}
                </div>
                <button disabled={!newDepartment.name} onClick={addDepartment} className='editor-add-modal-form-add-button'><span className='editor-add-modal-form-add-button-text'>Добавить</span><IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} /></button>
              </div>
            </Modal>
            <div className='arrange-list'>
              <button
                className='search-bar-button'
                onClick={toggleSearch}
                onMouseDown={(e) => e.preventDefault()}
              >
                <IonIcon className='search-bar-icon' icon={searchOutline} />
              </button>
              <input
                type="text"
                placeholder="Поиск"
                className='search-bar'
                value={search}
                ref={searchRef}
                onChange={handleSearchChange}
                onBlur={handleSearchBlur}
              />
              {searchActive && search && (
                <button onClick={() => { setSearch(''); searchRef.current.focus(); }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
              )}
              <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('name ASC'): return 'name DESC'; case ('name DESC'): return ''; default: return 'name ASC'; } })}>А-Я
                {sort === 'name ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
                {sort === 'name DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
                {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
              </button>
              <button className='fetch-entities' onClick={fetchDepartments}><IonIcon icon={checkmarkOutline} /></button>
            </div>
          </div>
          <div className='content-area'>
            <ul className='entity-list'>
              <li className='entity-header department'>
                <span>Название</span>
                <span className='entity-header-actions'>Действия</span>
              </li>
              {loadingDepartments ? (
                Array(10).fill().map((_, index) => (
                  <li key={index} className="entity-item department">
                    <span className='entity-item-name'><Skeleton width={200} /></span>
                      <Skeleton width={30} height={30} />
                      <Skeleton width={30} height={30} />
                  </li>
                ))
              ) : (
                departments.map(department => (
                  <li className={`entity-item department ${department.id === editDepartmentId ? 'editing' : ''}`} key={department.id}>
                    {department.id === editDepartmentId ? (
                      <>
                        <input
                          type="text"
                          value={editDepartment.name}
                          onChange={(e) => setEditDepartment({ ...editDepartment, name: e.target.value })}
                        />
                        <button className='entity-item-button done' onClick={updateDepartment}><IonIcon icon={checkmarkOutline} /></button>
                        <button className='entity-item-button cancel' onClick={cancelEditDepartment}><IonIcon icon={closeOutline} /></button>
                      </>
                    ) : (
                      <>
                        <span className='entity-item-name'>{department.name}</span>
                        <button className='entity-item-button edit' onClick={() => startEditDepartment(department)}><IonIcon icon={createOutline} /></button>
                        <button className='entity-item-button delete' onClick={() => deleteDepartment(department.id)}><IonIcon icon={trashOutline} /></button>
                      </>
                    )}
                  </li>
                ))
              )}
            </ul>
            <div className='page-navigation'>
              <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
              <span>Страница {page} из {totalPages}</span>
              <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
            </div>
            <div className='page-pagination'>
              <label>Количество строк на странице</label>
              <Select
                styles={paginationStyles}
                value={paginationOptions.find(option => option.value === limit)}
                onChange={(selectedOption) => { setLimit(selectedOption.value); }}
                options={paginationOptions}
                isSearchable={false}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DepartmentEditor;
