import React, { useCallback, useState, useMemo } from 'react';
import Select from 'react-select';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/analog_time_picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import DateObject from "react-date-object";
import "react-multi-date-picker/styles/layouts/mobile.css";
import './ClassDetailsForm.css';
import Russian from '../../../../../../assets/locales/react-multi-date-picker/russian_ru';
import { IonIcon } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons'

const ClassDetailsForm = ({ classDetails, onSave, teachers, locations, semester, maxWeeks }) => {
  const [details, setDetails] = useState(classDetails);
  const [dates, setDates] = useState(classDetails.dates || []);
  const [time, setTime] = useState(classDetails.time || null);
  const semesterStart = useMemo(() => new Date(semester.start_date), [semester.start_date]);
  const semesterEnd = useMemo(() => new Date(semester.end_date), [semester.end_date]);

  const handleChange = (name, value) => {
    switch (name) {
      case ("teacher"): {
        const selectedTeacher = teachers.find(t => t.id === parseInt(value?.value));
        setDetails(prev => ({ ...prev, [name]: value ? selectedTeacher : {} }));
        break;
      }
      case ("place"): {
        const selectedPlace = locations.find(l => l.id === parseInt(value?.value));
        setDetails(prev => ({ ...prev, [name]: value ? selectedPlace : {} }));
        break;
      }
      default: {
        setDetails(prev => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleDatesChange = (newDates) => {
    setDates(newDates);
    handleChange("dates", newDates);
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime);
    handleChange('time', newTime);

    const endTime = () => {

      const templateDuration = details.templateDuration;

      if (!newTime || !templateDuration) return null;

      const [durationHours, durationMinutes] = templateDuration.split(':').map(Number);

      const startHours = newTime.hour;
      const startMinutes = newTime.minute;

      let endHours = startHours + durationHours;
      let endMinutes = startMinutes + durationMinutes;

      if (endMinutes >= 60) {
        endHours += Math.floor(endMinutes / 60);
        endMinutes = endMinutes % 60;
      }

      if (endHours >= 24) {
        endHours = endHours % 24;
      }

      return {
        hour: endHours,
        minute: endMinutes,
        string: (endHours < 10 ? '0' + endHours : endHours) + ':' + (endMinutes < 10 ? '0' + endMinutes : endMinutes),
      };
    }

    handleChange('endTime', endTime());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(details);
  };

  const teacherOptions = teachers.map(teacher => ({
    value: teacher.id,
    label: `${teacher.surName} ${teacher.firstName} ${teacher.middleName}`
  }));

  const locationOptions = locations.map(location => ({
    value: location.id,
    label: location.name
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      borderRadius: '2rem',
      borderColor: '#ced4da',
      minHeight: '38px',
      maxWidth: '500px',
      height: '38px',
      boxShadow: 'none',
      ':hover': {
        borderColor: 'var(--primary-color)',
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0px 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '38px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '8px 2px',
      cursor: 'pointer',
      ':hover': {
        color: '#e03f34',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: '2px',
      cursor: 'pointer',
      ':hover': {
        color: '#6d5dfc',
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: '500px',
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      borderBottomLeftRadius: '1rem',
      borderBottomRightRadius: '1rem',
      marginTop: '2px',
      padding: "0 0 0 4px ",
      overflow: 'hidden',

    }),
    menuList: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      paddingRight: '4px',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#6d5dfc',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#5b0eeb',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '6px 10px',
      borderRadius: '2rem',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? state.isFocused ? '#5b0eeb' : '#6d5dfc' : state.isFocused ? 'var(--secondary-color)' : 'white',
      color: state.isSelected ? 'white' : state.isFocused ? 'var(--primary-color)' : 'black',
      transition: 'background-color 0.3s ease',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };


  const isWithinSemester = (date) => {
    const d = new Date(date);
    return d >= semesterStart && d <= semesterEnd;
  };

  const getWeekNumber = useCallback((d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  }, []);


  const isAvailableDay = useCallback((date) => {
    const DateDate = new Date(date.year, date.month.index, date.day);

    const startWeek = getWeekNumber(semesterStart);
    const dateWeek = getWeekNumber(DateDate);
    return date.weekDay.name === classDetails.day.toLowerCase() && (dateWeek - startWeek) % maxWeeks === classDetails.weekNumber - 1;
  }, [classDetails, getWeekNumber, maxWeeks, semesterStart]);

  const tileDisabled = ({ date }) => {
    return !isWithinSemester(date) || !isAvailableDay(date);
  };

  return (
    <form onSubmit={handleSubmit} className="class-details-form">
      <label>
        <span className='cell-type-name'>Преподаватель:</span>
        <Select
          name="teacher"
          value={teacherOptions.find(option => option.value === (details.teacher ? details.teacher.id : ""))}
          onChange={option => handleChange("teacher", option)}
          isClearable={true}
          placeholder={"Выберите преподавателя"}
          options={teacherOptions}
          noOptionsMessage={() => ("Преподаватели не найдены")}
          className="select-input"
          styles={customStyles}
        />
      </label>
      <label>
        <span className='cell-type-name'>Аудитория:</span>
        <Select
          name="place"
          value={locationOptions.find(option => option.value === (details.place ? details.place.id : ""))}
          onChange={option => handleChange("place", option)}
          isClearable={true}
          placeholder={"Выберите аудиторию"}
          options={locationOptions}
          noOptionsMessage={() => ("Аудитории не найдены")}
          className="select-input"
          styles={customStyles}
        />
      </label>
      <div className='class-form-cyclic'>
        <label className='class-form-cyclic'>
          <span className='cell-type-name'>Цикличное</span>
          <input
            type="checkbox"
            name="cyclic"
            checked={details.cyclic}
            onChange={e => setDetails(prev => ({ ...prev, cyclic: e.target.checked }))}
          />
        </label>
        {!details.cyclic && (
          <label className='class-form-cyclic'>
            <span className='cell-type-name'>Периодичное</span>
            <input
              type="checkbox"
              name="range"
              checked={!!details.range}  // Ensure it's always boolean
              onChange={e => setDetails(prev => ({ ...prev, range: e.target.checked }))}
            />
          </label>
        )}
      </div>
      {!details.cyclic && (
        <>
          <label>
            <span className='cell-type-name'>Даты занятий:</span>
          </label>
          <DatePicker
            containerClassName='content-box'
            inputClass='date-picker-input'
            currentDate={new DateObject(semesterStart)}
            value={dates.length ? dates : []}
            onChange={handleDatesChange}
            multiple
            range={details.range ? true : undefined}
            weekStartDayIndex={1}
            format='DD.MM.YYYY'
            locale={Russian()}
            plugins={[
              <DatePanel />
            ]}
            minDate={semesterStart}
            maxDate={semesterEnd}
            mapDays={({ date }) => ({
              disabled: tileDisabled({ date })
            })}
          />
        </>
      )}
      <label>
        <span className='cell-type-name'>Начало</span>
      </label>
      <DatePicker
        containerClassName='time-box'
        value={time || ""}
        inputClass='time-picker-input'
        disableDayPicker
        format='HH:mm'
        onChange={handleTimeChange}
        plugins={[<TimePicker
          hideSeconds
        />]}

      />
      <button type="submit" className='save-button'><IonIcon icon={checkmarkOutline} /></button>
    </form>
  );
};

export default ClassDetailsForm;
