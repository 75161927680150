import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import AcademicCalendar from './AcademicCalendar';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { createOutline, calendarOutline, enterOutline } from 'ionicons/icons';//searchOutline,
import logo from '../../assets/images/logo.png';
import './Navbar.css';
import UserModal from './UserModal';

const Navbar = () => {
  const { isLogin, handleLogout, role, openAuthModal, firstname } = useContext(AuthContext);
  const [isEditor, setIsEditor] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userButtonRef = useRef(null); // Ссылка на кнопку

  useEffect(() => {
    setIsEditor(() => (['admin', 'editor'].includes(role)));
  }, [role]);

  const toggleModal = () => {
    setIsModalOpen(prevState => !prevState);
  };

  return (
    <div className='container'>
      <nav className='nav-bar'>
        <ul className='nav-tabs-list'>
          <li className='nav-item'>
            <Link to='https://stgmu.ru/'>
              <img src={logo} alt='Основной сайт университета' height={60} />
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/' className='home-link'>
              <IonIcon className='home-link-icon' icon={calendarOutline} />
              <span>Расписание</span>
            </Link>
          </li>
          {isEditor && <li className='nav-item'>
            <Link className='editor-link' to='/editor'>
              <IonIcon className='editor-link-icon' icon={createOutline} />
              <span>Редактор</span>
            </Link>
          </li>}
        </ul>
        <AcademicCalendar />
        {/* <div className='nav-search'>
          <button className='search-button'><IonIcon icon={searchOutline} /></button>
        </div> */}
        {isLogin ? (
          <div className='nav-user'>
            <button
              className='user-button'
              onClick={toggleModal}
              ref={userButtonRef}  // Установите ссылку на кнопку
            >
              {firstname && firstname.slice(0, 1)}
            </button>
          </div>
        ) : (
          <button className='login-button' onClick={openAuthModal}><IonIcon icon={enterOutline} /></button>
        )}
      </nav>
      <UserModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onLogout={handleLogout}
        buttonRef={userButtonRef} // Передайте ссылку на кнопку в модальное окно
      />
    </div>
  );
};

export default Navbar;
