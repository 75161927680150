import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Select from 'react-select';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import paginationStyles from './css/paginationSelectStyles';
import customMultiStyles from './css/customMultiSelectStyles';
import customMultiEditorStyles from './css/customMultiSelectStylesEditor';
import LoaderContext from '../../../../context/LoaderContext';

const EducatorEditor = () => {
  const [educators, setEducators] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartmentsFilter, setSelectedDepartmentsFilter] = useState([]); // Новое состояние для фильтра кафедр
  const [newEducator, setNewEducator] = useState({ firstName: '', middleName: '', surName: '', departments: [] });
  const [editEducator, setEditEducator] = useState({ firstName: '', middleName: '', surName: '', departments: [] });
  const [editEducatorId, setEditEducatorId] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [loadingEducators, setLoadingEducators] = useState(false); // Состояние загрузки преподавателей
  const searchRef = useRef(null);
  const buttonRef = useRef(null);

  const { showLoader, hideLoader, setLoadingMessage } = useContext(LoaderContext);

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const fetchDepartments = useCallback(async () => {
    try {
      setLoadingMessage("Загружаем кафедры");
      showLoader();
      const response = await api.get('/department/all');
      setDepartments(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке кафедр', error);
    } finally {
      hideLoader();
      setLoadingMessage("Расписание загружается");
    }
  }, [showLoader, hideLoader, setLoadingMessage]);

  useEffect(() => { fetchDepartments() }, [fetchDepartments]);

  const dynamicSelectStyles = (baseStyles) => ({
    ...baseStyles,
    container: (provided, state) => ({
      ...provided,
      width: 'auto',
    }),
  });

  const fetchEducators = useCallback(async () => {
    try {
      setLoadingEducators(true);
      let req = `/educator/with-departments?page=${page}&limit=${limit}`;
      if (search) {
        req += `&searchFields=firstName&searchFields=middleName&searchFields=surName&search=${search}`;
      }
      if (sort) req += `&sort=${sort}`;
      if (selectedDepartmentsFilter.length > 0) {
        const departmentIds = selectedDepartmentsFilter.map(dep => dep.value).join(',');
        req += `&departments=${departmentIds}`;
      }

      const response = await api.get(req);
      const data = response.data;
      setEducators(data.data || []);
      setTotalPages(data.pagination ? data.pagination.totalPages : 1);
      if (page > (data.pagination ? data.pagination.totalPages : 1)) {
        setPage(1);
      }
    } catch (error) {
      console.error('Ошибка при загрузке преподавателей', error);
      setEducators([]);
      setTotalPages(1);
    } finally {
      setLoadingEducators(false);
    }
  }, [page, limit, search, sort, selectedDepartmentsFilter]);

  useEffect(() => { fetchEducators(); }, [fetchEducators]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const addEducator = async () => {
    try {
      await api.post('/educator', { ...newEducator, departments: newEducator.departments });
      toast.success('Преподаватель добавлен!');
      fetchEducators();
      setNewEducator({ firstName: '', middleName: '', surName: '', departments: [] });
      setShowAddForm(false);
    } catch (error) {
      console.error('Ошибка при добавлении преподавателя', error);
      toast.error('Ошибка при добавлении преподавателя');
    }
  };

  const deleteEducator = async (id) => {
    try {
      await api.delete(`/educator/${id}`);
      toast.success('Преподаватель удален!');
      fetchEducators();
    } catch (error) {
      console.error('Ошибка при удалении преподавателя', error);
      toast.error('Ошибка при удалении преподавателя');
    }
  };

  const startEditEducator = (educator) => {
    setEditEducatorId(educator.id);
    setEditEducator({
      firstName: educator.firstName,
      middleName: educator.middleName,
      surName: educator.surName,
      departments: educator.departments || [] // Убедитесь, что departments - это массив
    });
  };

  const cancelEditEducator = () => {
    setEditEducatorId(null);
    setEditEducator({ firstName: '', middleName: '', surName: '' });
  };

  const updateEducator = async () => {
    try {
      await api.put(`/educator/${editEducatorId}`, { ...editEducator, departments: editEducator.departments });
      toast.success('Преподаватель обновлен!');
      fetchEducators();
      cancelEditEducator();
    } catch (error) {
      console.error('Ошибка при обновлении преподавателя', error);
      toast.error('Ошибка при обновлении преподавателя');
    }
  };


  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  const toggleAddForm = () => {
    setIsAnimating(true);

    if (!showAddForm) {
      setTimeout(() => {
        setShowAddForm(true);
        setIsAnimating(false);
      }, 150);
    } else {
      setIsClosing(true);
      setShowAddForm(false);
      setIsAnimating(false);
      setIsClosing(false);
    }
  };

  useEffect(() => {
    const updateModalPosition = () => {
      if (buttonRef.current && showAddForm) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const modalContent = document.querySelector('.editor-add-modal');

        if (modalContent) {
          modalContent.style.top = `${buttonRect.bottom}px`;
          modalContent.style.left = `${buttonRect.left}px`;
        }
      }
    };

    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    updateModalPosition();

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, [showAddForm]);

  return (
    <div className='extended-container'>
      <div className='actions-list'>
        <button
          ref={buttonRef}
          onClick={toggleAddForm}
          className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
        >
          <span className="add-entity-button-text">
            <span className="text-add">Добавить преподавателя</span>
            <span className="text-cancel">Отмена</span>
          </span>
          <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
        </button>
        <Modal
          isOpen={showAddForm}
          onRequestClose={toggleAddForm}
          contentLabel="Добавить преподавателя"
          className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
          overlayClassName="editor-add-overlay"
          closeTimeoutMS={300}
          style={{
            content: {
              top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
              left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
              transform: 'translate(0, 0)',
            },
          }}
        >
          <h3>Добавить преподавателя</h3>
          <div className='editor-add-modal-form'>
            <div className='editor-add-modal-form-input-wrapper'>
              <input
                type="text"
                placeholder="Имя"
                value={newEducator.firstName}
                onChange={(e) => setNewEducator({ ...newEducator, firstName: e.target.value })}
              />
              {newEducator.firstName && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewEducator({ ...newEducator, firstName: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <input
                type="text"
                placeholder="Отчество"
                value={newEducator.middleName}
                onChange={(e) => setNewEducator({ ...newEducator, middleName: e.target.value })}
              />
              {newEducator.middleName && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewEducator({ ...newEducator, middleName: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <input
                type="text"
                placeholder="Фамилия"
                value={newEducator.surName}
                onChange={(e) => setNewEducator({ ...newEducator, surName: e.target.value })}
              />
            </div>
            <Select
              isMulti
              styles={customMultiStyles}
              value={newEducator.departments.map(dep => ({ value: dep.id, label: dep.name }))}
              onChange={(selectedOptions) => setNewEducator({
                ...newEducator,
                departments: selectedOptions.map(option => ({ id: option.value, name: option.label })),
              })}
              options={departments.map(department => ({ value: department.id, label: department.name }))}
              noOptionsMessage={() => "Кафедры не найдены"}
              placeholder="Выберите кафедры"
            />
            <button disabled={!newEducator.firstName || !newEducator.surName} onClick={addEducator} className='editor-add-modal-form-add-button'>
              <span className='editor-add-modal-form-add-button-text'>Добавить</span>
              <IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} />
            </button>

          </div>
        </Modal>
        <div className='arrange-list'>
          <button
            className='search-bar-button'
            onClick={toggleSearch}
            onMouseDown={(e) => e.preventDefault()}
          >
            <IonIcon className='search-bar-icon' icon={searchOutline} />
          </button>
          <input
            type="text"
            placeholder="Поиск"
            className='search-bar'
            value={search}
            ref={searchRef}
            onChange={handleSearchChange}
            onBlur={handleSearchBlur}
          />
          {searchActive && search && (
            <button onClick={() => { setSearch(''); searchRef.current.focus() }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
          )}
          <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('firstName ASC'): return 'firstName DESC'; case ('firstName DESC'): return ''; default: return 'firstName ASC' } })}>Имя
            {sort === 'firstName ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
            {sort === 'firstName DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
            {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
          </button>
          <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('surName ASC'): return 'surName DESC'; case ('surName DESC'): return ''; default: return 'surName ASC' } })}>Фамилия
            {sort === 'surName ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
            {sort === 'surName DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
            {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
          </button>
          <Select
            isMulti
            styles={dynamicSelectStyles(customMultiStyles)}
            value={selectedDepartmentsFilter}
            onChange={(selectedOptions) => setSelectedDepartmentsFilter(selectedOptions || [])}
            options={departments.map(department => ({ value: department.id, label: department.name }))}
            placeholder="Фильтр по кафедрам"
            noOptionsMessage={() => "Кафедры не найдены"}
          />
          <button className='fetch-entities' onClick={fetchEducators}><IonIcon icon={checkmarkOutline} /></button>
        </div>
      </div>
      <div className='content-area'>
        <ul className='entity-list'>
          <li className='entity-header educator'>
            <span>Имя</span>
            <span>Отчество</span>
            <span>Фамилия</span>
            <span>Кафедры</span>
            <span className='entity-header-actions'>Действия</span>
          </li>
          {loadingEducators ? (
            Array(10).fill().map((_, index) => (
              <li key={index} className="entity-item educator">
                <span className='entity-item-name'><Skeleton width={100} /></span>
                <span><Skeleton width={100} /></span>
                <span><Skeleton width={100} /></span>
                <span><Skeleton width={100} /></span>
                <Skeleton width={30} height={30} />
                <Skeleton width={30} height={30} />
              </li>
            ))
          ) : (
            educators.map(educator => (
              <li className={`entity-item educator ${educator.id === editEducatorId ? 'editing' : ''}`} key={educator.id}>
                {educator.id === editEducatorId ? (
                  <>
                    <input
                      placeholder='Имя'
                      type="text"
                      value={editEducator.firstName}
                      onChange={(e) => setEditEducator({ ...editEducator, firstName: e.target.value })}
                    />
                    <input
                      placeholder='Отчество'
                      type="text"
                      value={editEducator.middleName}
                      onChange={(e) => setEditEducator({ ...editEducator, middleName: e.target.value })}
                    />
                    <input
                      placeholder='Фамилия'
                      type="text"
                      value={editEducator.surName}
                      onChange={(e) => setEditEducator({ ...editEducator, surName: e.target.value })}
                    />
                    <Select
                      isMulti
                      className='educator-departments-editor-input'
                      styles={customMultiEditorStyles}
                      value={editEducator.departments.map(dep => ({ value: dep.id, label: dep.name }))} // Используем editEducator
                      onChange={(selectedOptions) => setEditEducator({
                        ...editEducator,
                        departments: selectedOptions.map(option => ({ id: option.value, name: option.label })), // Сохраняем выбранные кафедры в editEducator
                      })}
                      options={departments.map(department => ({ value: department.id, label: department.name }))}
                      noOptionsMessage={() => "Кафедры не найдены"}
                      placeholder="Выберите кафедры"
                    />
                    <button className='entity-item-button done' onClick={updateEducator}><IonIcon icon={checkmarkOutline} /></button>
                    <button className='entity-item-button cancel' onClick={cancelEditEducator}><IonIcon icon={closeOutline} /></button>
                  </>
                ) : (
                  <>
                    <span className='entity-item-name'>{educator.firstName}</span>
                    <span>{educator.middleName}</span>
                    <span>{educator.surName}</span>
                    <ul>
                      {educator.departments.map(dep => (
                        <li key={dep.id}>{dep.name}</li>
                      ))}
                    </ul>
                    <button className='entity-item-button edit' onClick={() => startEditEducator(educator)}><IonIcon icon={createOutline} /></button>
                    <button className='entity-item-button delete' onClick={() => deleteEducator(educator.id)}><IonIcon icon={trashOutline} /></button>
                  </>
                )}
              </li>
            ))
          )}
        </ul>
        <div className='page-navigation'>
          <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
          <span>Страница {page} из {totalPages}</span>
          <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
        </div>
        <div className='page-pagination'>
          <label>Количество строк на странице</label>
          <Select
            styles={paginationStyles}
            value={paginationOptions.find(option => option.value === limit)}
            onChange={(selectedOption) => { setLimit(selectedOption.value) }}
            options={paginationOptions}
            isSearchable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default EducatorEditor;
