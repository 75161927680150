import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ClassDetailsForm from './ClassDetailsForm';
import './ScheduleCell.css';
import { IonIcon } from '@ionic/react';
import { closeOutline, trashOutline, createOutline } from 'ionicons/icons';

const ScheduleCell = ({ weekIndex, dayIndex, groupIndex, lastIndex, lessonIndex, schedule, onDropClass,
  onRemoveClass, onMoveClass, resizing, setResizing, mergeClass, setCursorCell, resizingRef, cursorCellRef,
  teachers, locations, onUpdateClass, getBoundaries, semester, maxWeeks, isEditing, setIsEditing }) => {

  const wrapperRef = useRef(null);
  const cellRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);

  const isEditingThisCell = useCallback(() => {
    return weekIndex === isEditing.weekIndex &&
      dayIndex === isEditing.dayIndex &&
      isEditing.groupIndex === groupIndex &&
      isEditing.lessonIndex === lessonIndex
  }, [isEditing, dayIndex, groupIndex, lessonIndex, weekIndex]);
  const isEditingSomeCell = useCallback(() => { return Object.values(isEditing).length > 0 }, [isEditing])

  useEffect(() => {
    setIsResizing(!Object.values(resizing).includes(null));
  }, [resizing]);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['CLASS_TEMPLATE', 'SCHEDULE_ITEM'],
    canDrop: () => !schedule,
    drop: (item) => {
      if (!schedule) {
        if (item.type === 'SCHEDULE_ITEM') {
          onMoveClass(item, weekIndex, dayIndex, groupIndex, lessonIndex);
        } else {
          onDropClass(item.template, weekIndex, dayIndex, groupIndex, lessonIndex);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleClassClick = () => {
    if (schedule && !isEditingSomeCell()) {
      setIsEditing({ weekIndex, dayIndex, groupIndex, lessonIndex });
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: 'SCHEDULE_ITEM',
    item: () => {
      return { ...schedule, type: 'SCHEDULE_ITEM', weekIndex, dayIndex, groupIndex, lessonIndex };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !!schedule && !isEditingSomeCell(),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        if (wrapperRef.current) {
          resetStyle();
        }
      }
    },
  });

  useEffect(() => {
    if (cellRef.current) {
      drop(cellRef);
    }
  }, [cellRef, drop]);

  const addCellClass = (className) => {
    if (cellRef.current) {
      cellRef.current.classList.add(className);
    }
  };

  const removeCellClass = (className) => {
    if (cellRef.current) {
      cellRef.current.classList.remove(className);
    }
  };

  const handleRemoveClass = () => {
    onRemoveClass(weekIndex, dayIndex, groupIndex, lessonIndex);
  };

  const onMouseMove = useCallback((e, side, initWidth) => {
    if (wrapperRef.current && schedule) {
      const tableRect = document.querySelector('.table').getBoundingClientRect();
      const rect = wrapperRef.current.getBoundingClientRect();
      let newWidth;

      if (side === 'right') {
        newWidth = Math.min(e.clientX - rect.left - 2, tableRect.right - rect.left);
        wrapperRef.current.style.left = '1px';
      } else if (side === 'left') {
        newWidth = Math.min(rect.right - e.clientX - 2, rect.right - tableRect.left);
        wrapperRef.current.style.right = schedule.sharedGroups.length > 0 ? `${- initWidth + cellRef.current.getBoundingClientRect().width + 1}px` : "1px";
      }

      wrapperRef.current.style.width = `${newWidth}px`;
    }
  }, [schedule]);

  const resetStyle = useCallback(() => {
    if (cellRef)
      cellRef.current.style = '';
    if (wrapperRef)
      wrapperRef.current.style = '';
    if (document.body.style)
      document.body.style.userSelect = '';
    removeCellClass('touched');
  }, []);

  const handleResizeStop = useCallback((onMouseMoveBound) => {
    window.removeEventListener('mousemove', onMouseMoveBound);
    mergeClass(resizingRef.current, cursorCellRef.current, schedule);
    setResizing({ weekIndex: null, dayIndex: null, groupIndex: null, side: null, lessonIndex: null, boundaries: {} });
    resetStyle();
  }, [mergeClass, setResizing, resetStyle, resizingRef, cursorCellRef, schedule]);

  const handleResizeStart = useCallback((side) => {
    if (!isResizing && !isEditingSomeCell()) {
      const currentResizing = { weekIndex, dayIndex, groupIndex, side, lastIndex, lessonIndex, boundaries: getBoundaries(weekIndex, dayIndex, groupIndex, lastIndex, lessonIndex) };
      setResizing(() => {
        resizingRef.current = currentResizing;
        return currentResizing;
      });
      setCursorCell(side === "right" && schedule.sharedGroups.length !== 0 ? schedule.sharedGroups.at(-1) : groupIndex);
      const cellRect = cellRef.current.getBoundingClientRect();
      const wrapperRect = wrapperRef.current.getBoundingClientRect();

      if (schedule.sharedGroups.length === 0)
        cellRef.current.style.width = `${cellRect.width}px`;
      wrapperRef.current.style.overflow = 'hidden';
      cellRef.current.style.height = `${cellRect.height}px`;
      cellRef.current.style.position = 'relative';
      wrapperRef.current.style.width = `${wrapperRect.width}px`;
      wrapperRef.current.style.maxHeight = `${wrapperRect.height}px`;
      wrapperRef.current.style.minHeight = `${wrapperRect.height}px`;
      wrapperRef.current.style.position = 'absolute';
      wrapperRef.current.style.top = '1px';
      wrapperRef.current.style.zIndex = '900';
      document.body.style.userSelect = 'none';

      const onMouseMoveBound = (event) => onMouseMove(event, side, cellRect.width);

      window.addEventListener('mousemove', onMouseMoveBound);
      window.addEventListener('mouseup', () => handleResizeStop(onMouseMoveBound), { once: true });
    }
  }, [dayIndex, groupIndex, handleResizeStop, isResizing, lastIndex, lessonIndex, onMouseMove, resizingRef, schedule, setCursorCell, setResizing, weekIndex, getBoundaries, isEditingSomeCell]);


  const handleSaveClassDetails = (updatedDetails) => {
    onUpdateClass(weekIndex, dayIndex, groupIndex, lessonIndex, updatedDetails);
    setIsEditing({});
  };

  useEffect(() => {
    const cellElement = cellRef.current;
    const handleMouseEnter = () => {
      addCellClass('touched');
      setCursorCell(groupIndex);
    };
    const handleMouseLeave = () => {
      removeCellClass('touched');
    };

    if (
      (!schedule || schedule.sharedGroup === resizing.groupIndex || groupIndex === resizing.groupIndex) &&
      resizing.weekIndex === weekIndex &&
      resizing.dayIndex === dayIndex &&
      resizing.lessonIndex === lessonIndex &&
      cellElement &&
      (
        (resizing.side === "right" && groupIndex <= resizing.boundaries.right && groupIndex >= resizing.groupIndex) ||
        (resizing.side === "left" && groupIndex >= resizing.boundaries.left && groupIndex <= resizing.lastIndex)
      )
    ) {
      if (cursorCellRef.current === groupIndex)
        addCellClass('touched');
      addCellClass('selected');

      cellElement.addEventListener('mouseenter', handleMouseEnter);
      cellElement.addEventListener('mouseleave', handleMouseLeave);


      return () => {
        removeCellClass('selected');
        removeCellClass('touched');
        cellElement.removeEventListener('mouseenter', handleMouseEnter);
        cellElement.removeEventListener('mouseleave', handleMouseLeave);

      };
    }
  }, [resizing, weekIndex, dayIndex, schedule, groupIndex, setCursorCell, cursorCellRef, handleResizeStart, lessonIndex]);

  return (
    (!schedule) ||
      (schedule && !schedule.shared) ||
      (schedule && schedule.shared && resizing.dayIndex === dayIndex && resizing.weekIndex === weekIndex && resizing.lessonIndex === lessonIndex && groupIndex >= resizing.boundaries.left && groupIndex <= resizing.boundaries.right) ? (
      <td
        ref={cellRef}
        className={`schedule-cell ${isOver && canDrop ? 'highlight' : ''} ${!canDrop && schedule ? 'occupied' : ''}`}
        colSpan={(schedule && schedule.sharedGroups && schedule.sharedGroups.length) &&
          (resizing.dayIndex !== dayIndex || resizing.weekIndex !== weekIndex || resizing.lessonIndex !== lessonIndex || groupIndex < resizing.boundaries.left || groupIndex > resizing.boundaries.right) ?
          schedule.sharedGroups.length + 1 : undefined}
      >
        {
          schedule && !schedule.shared ? (
            <div className='cell-wrapper' ref={wrapperRef}>
              <div
                className="resize-handle resize-handle-left"
                onMouseDown={() => handleResizeStart('left')}
              />
              <div ref={drag} className={`class-details${isDragging ? ' dragging' : ''}`}>
                <div className='cell-name'>{schedule.templateName}</div>
                <div><span className='cell-type-name'>Тип</span><div className='cell-value'> {schedule.templateType}</div></div>
                <div><span className='cell-type-name'>Длительность</span><div className='cell-value'> {schedule.templateDuration.slice(0, 2)}:{schedule.templateDuration.slice(3, 5)}</div></div>
                {schedule.templateDescription && <div><span className='cell-type-name'>Примечание</span><div className='cell-value'> {schedule.templateDescription}</div></div>}
                {!isEditingThisCell() && (<>
                  <div><span className='cell-type-name'>Преподаватель</span><div className='cell-value'> {schedule.teacher ? schedule.teacher.surName : "Не определён"}</div></div>
                  <div><span className='cell-type-name'>Аудитория</span><div className='cell-value'> {schedule.place ? schedule.place.name + ' ' + schedule.place.address : "Не определена"}</div></div>
                  <div className='cell-checkbox'><span className='cell-type-name'>Цикличное</span><input type='checkbox' checked={schedule.cyclic} disabled />
                    {!schedule.cyclic && <><span className='cell-type-name'>Периодичное</span><input type='checkbox' checked={schedule.range} disabled /></>}</div>
                  <div><span className='cell-type-name'>Начало</span><div className='cell-value'> {schedule.time ? ((schedule.time.hour < 10 ? '0' + schedule.time.hour : schedule.time.hour) + ':' + (schedule.time.minute < 10 ? '0' + schedule.time.minute : schedule.time.minute)) : 'Не задано'}</div></div>
                  {schedule.endTime && <div><span className='cell-type-name'>Окончание</span><div className='cell-value'> {schedule.endTime.string}</div></div>}
                </>)}
                {!isEditingThisCell() && (<button className={`edit-button ${isEditingSomeCell() ? 'disabled' : ''}`} onClick={handleClassClick} disabled={isEditingSomeCell()}><IonIcon icon={createOutline} /></button>)}
                {isEditingThisCell() && (
                  <ClassDetailsForm
                    classDetails={schedule}
                    onSave={handleSaveClassDetails}
                    teachers={teachers}
                    locations={locations}
                    semester={semester}
                    maxWeeks={maxWeeks}
                  />
                )}
                {isEditingThisCell() && <button onClick={() => setIsEditing({})} className='cancel-edit-button'><IonIcon icon={closeOutline} /></button>}
                {!isEditingThisCell() && <button onClick={handleRemoveClass} className="remove-button"><IonIcon icon={trashOutline} /></button>}
              </div>
              <div
                className="resize-handle resize-handle-right"
                onMouseDown={() => handleResizeStart('right')}
              />
            </div>
          ) : (
            null
          )}
      </td>
    ) : null
  );
};

export default ScheduleCell;
