import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import api from '../../../../services/api';
import ScheduleTable from './components/ScheduleTable/ScheduleTable';
import Sidebar from './components/Sidebar';
import { toast } from 'react-toastify';
import './CourseScheduleEditor.css';

const CourseScheduleEditor = () => {
  const [faculties, setFaculties] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedFOE, setSelectedFOE] = useState('Очная');
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [groups, setGroups] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchFaculties = async () => {
      try {
        const response = await api.get('/faculty/all');
        setFaculties(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке факультетов', error);
      }
    };
    fetchFaculties();
  }, []);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await api.get(`/educator/all`);
        setTeachers(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке преподавателей', error);
      }
    }

    fetchTeachers();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await api.get(`/auditorium/all`);
        setLocations(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке аудиторий', error);
      }
    }

    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchSpecialities = async () => {
      if (selectedFaculty) {
        try {
          const response = await api.get(`/speciality/all-by-foreign-key/faculty_id/${selectedFaculty.id}`);
          setSpecialities(response.data);
        } catch (error) {
          console.error('Ошибка при загрузке специальностей', error);
        }
      } else {
        setSpecialities([]);
      }
      setSelectedSpeciality(null);
      setCourses([]);
      setSelectedCourse(null);
      setGroups([]);
    };
    fetchSpecialities();
  }, [selectedFaculty]);

  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedSpeciality) {
        try {
          const response = await api.get(`/course/all-by-foreign-key/speciality_id/${selectedSpeciality.id}`);
          setCourses(response.data || []);
        } catch (error) {
          console.error('Ошибка при загрузке курсов', error);
        }
      } else {
        setCourses([]);
      }
      setSelectedCourse(null);
      setGroups([]);
    };
    fetchCourses();
  }, [selectedSpeciality]);

  useEffect(() => {
    const fetchSemesters = async () => {
      if (selectedCourse) {
        try {
          const response = await api.get(`/semester/all-by-foreign-key/course_id/${selectedCourse.id}`);
          setSemesters(response.data);
        } catch (error) {
          console.error('Ошибка при загрузке семестров', error);
        }
      }
      else {
        setSemesters([]);
      }
      setSelectedSemester(null);
    }

    fetchSemesters();
  }, [selectedCourse]);

  const fetchGroups = useCallback(async () => {
    if (selectedSemester) {
      try {
        const response = await api.get(`/group_/all-by-foreign-key/course_id/${selectedCourse.id}`);
        setGroups(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке групп', error);
      }
    } else {
      setGroups([]);
    }
  }, [selectedCourse, selectedSemester]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const fetchTemplates = useCallback(async () => {
    try {
      const response = await api.get('/class_template');
      setTemplates(response.data.data || []);
    } catch (error) {
      toast.error('Ошибка при загрузке шаблонов занятий');
    }
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const fetchWeeks = useCallback(async () => {
    if (selectedCourse) {
      try {
        const response = await api.get(`/week/all-by-foreign-key/course_id/${selectedCourse.id}`);
        setWeeks(response.data || []);
      } catch (error) {
        toast.error('Ошибка при загрузке шаблонов занятий');
      }
    } else {
      setWeeks([]);
    }
  }, [selectedCourse]);

  useEffect(() => {
    fetchWeeks();
  }, [fetchWeeks]);

  const handleAddWeek = async (week) => {
    try {
      const response = await api.post('/week', { number: week.number, course_id: selectedCourse.id });
      toast.success('Учебная неделя успешно добавлена!');
      setWeeks([...weeks, response.data]);
    } catch (error) {
      console.error('Ошибка при добавлении недели', error);
      toast.error('Ошибка при добавлении недели');
    }
    fetchWeeks();
  }

  const handleDeleteWeek = async (week) => {
    try {
      await api.delete(`/week/${week.id}`);
      toast.success('Учебная неделя успешно удалена!');
      fetchWeeks();
    } catch (error) {
      console.error('Ошибка при удалении недели', error);
      toast.error('Ошибка при удалении недели');
    }
  }

  const handleAddGroup = async (group) => {
    try {
      const response = await api.post('/group_', { ...group, course_id: selectedCourse.id });
      setGroups([...groups, response.data]);
      toast.success('Группа успешно добавлена!');
    } catch (error) {
      console.error('Ошибка при добавлении группы', error);
      toast.error('Ошибка при добавлении группы');
    }
    fetchGroups();
  };

  const handleUpdateGroup = async (group) => {
    try {
      await api.put(`/group_/${group.id}`, { name: group.name, course_id: selectedCourse.id, form_of_education: selectedFOE });
      setGroups(groups.map(g => g.id === group.id ? group : g));
      toast.success('Группа успешно обновлена!');
    } catch (error) {
      console.error('Ошибка при обновлении группы', error);
      toast.error('Ошибка при обновлении группы');
    }
    fetchGroups();
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await api.delete(`/group_/${groupId}`);
      setGroups(groups.filter(g => g.id !== groupId));
      toast.success('Группа успешно удалена!');
    } catch (error) {
      console.error('Ошибка при удалении группы', error);
      toast.error('Ошибка при удалении группы');
    }
    fetchGroups();
  };

  const handleAddClass = async (newClass) => {
    // Implement add class logic here
  };

  const facultyOptions = faculties.map(faculty => ({ value: faculty.id, label: faculty.name }));
  const specialityOptions = specialities.map(speciality => ({ value: speciality.id, label: speciality.identifier + ' ' + speciality.name }));
  const courseOptions = courses.map(course => ({ value: course.id, label: course.name }));
  const semesterOptions = semesters.map(semester => ({
    value: semester.id,
    label: `${semester.name} (с ${new Date(semester.start_date).toLocaleDateString()} по ${new Date(semester.end_date).toLocaleDateString()})`
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: '2rem',
      borderColor: '#ced4da',
      minHeight: '38px',
      height: '38px',
      boxShadow: '0 0 1px 0 rgba(109, 93, 252, 0.15), 0 6px 12px 0 rgba(109, 93, 252, 0.15)',
      ':hover': {
        borderColor: 'var(--primary-color)',
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0px 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '38px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      padding: '8px 2px',
      cursor: 'pointer',
      ':hover': {
        color: '#e03f34',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: '2px',
      cursor: 'pointer',
      ':hover': {
        color: '#6d5dfc',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      borderBottomLeftRadius: '1rem',
      borderBottomRightRadius: '1rem',
      marginTop: '2px',
      padding: "0 0 0 4px ",
      overflow: 'hidden',

    }),
    menuList: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      paddingRight: '4px',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#6d5dfc',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#5b0eeb',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '6px 10px',
      borderRadius: '2rem',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? state.isFocused ? '#5b0eeb' : '#6d5dfc' : state.isFocused ? 'var(--secondary-color)' : 'white',
      color: state.isSelected ? 'white' : state.isFocused ? 'var(--primary-color)' : 'black',
      transition: 'background-color 0.3s ease',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '4px 0',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };

  return (
    <div>
      <h3 className='visually-hidden'>Расписание курса</h3>
      <div className='extended-container'>
        <div className='selects'>
          <ul className='filters-list'>
            <li>
              <label className='label-select'>
                <span className='filter-name'>Факультет</span>

                <Select
                  value={selectedFaculty ? { value: selectedFaculty.id, label: selectedFaculty.name } : null}
                  options={facultyOptions}
                  onChange={(option) => setSelectedFaculty(option ? faculties.find(faculty => faculty.id === option.value) : null)}
                  placeholder="Выберите факультет"
                  styles={customStyles}
                  isClearable={true}
                  noOptionsMessage={() => ("Факультеты не найдены")}
                />
              </label>
            </li>
            {selectedFaculty && (
              <li>
                <label className='label-select'>
                  <span className='filter-name'>Специальность</span>
                  <Select
                    value={selectedSpeciality ? { value: selectedSpeciality.id, label: selectedSpeciality.identifier + ' ' + selectedSpeciality.name } : null}
                    options={specialityOptions}
                    onChange={(option) => setSelectedSpeciality(option ? specialities.find(speciality => speciality.id === option.value) : null)}
                    placeholder="Выберите специальность"
                    styles={customStyles}
                    isClearable={true}
                    noOptionsMessage={() => ("Специальности не найдены")}
                  />
                </label>
              </li>
            )}
            {selectedSpeciality && (
              <li>
                <label className='label-select'>
                  <span className='filter-name'>Курс</span>
                  <Select
                    value={selectedCourse ? { value: selectedCourse.id, label: selectedCourse.name } : null}
                    options={courseOptions}
                    onChange={(option) => setSelectedCourse(option ? courses.find(course => course.id === option.value) : null)}
                    placeholder="Выберите курс"
                    styles={customStyles}
                    isClearable={true}
                    noOptionsMessage={() => ("Курсы не найдены")}
                  />
                </label>
              </li>
            )}
            {selectedCourse && (
              <li>
                <label className='label-select'>
                  <span className='filter-name'>Форма обучения</span>
                  <Select
                    value={{ value: selectedFOE, label: selectedFOE }}
                    onChange={(option) => setSelectedFOE(option ? option.value : null)}
                    options={[
                      { value: 'Очная', label: 'Очная' },
                      { value: 'Заочная', label: 'Заочная' }
                    ]}
                    placeholder="Выберите форму обучения"
                    styles={customStyles}
                  />
                </label>
              </li>
            )}
          </ul>
          {selectedCourse && (<label className='semester-select label-select'>
            <span className='filter-name'>Учебный семестр</span>
            <Select
              value={selectedSemester ? { value: selectedSemester.id, label: `${selectedSemester.name} (с ${new Date(selectedSemester.start_date).toLocaleDateString()} по ${new Date(selectedSemester.end_date).toLocaleDateString()})` } : null}
              options={semesterOptions}
              onChange={(option) => setSelectedSemester(option ? semesters.find(semester => semester.id === option.value) : null)}
              placeholder="Выберите учебный семестр"
              styles={customStyles}
              isClearable={true}
              noOptionsMessage={() => ("Учебные семестры не найдены")}
            />
          </label>)}
        </div>
      </div>
      {selectedCourse && selectedSemester && (
        <>
          <div className='extended-container'>
            <button className='show-templates-button' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              {isSidebarOpen ? 'Скрыть шаблоны занятий' : 'Показать шаблоны занятий'}
            </button>
          </div>
          <ScheduleTable
            groups={groups.filter((group) => group.form_of_education === selectedFOE)}
            foe={selectedFOE}
            weeks={weeks}
            onAddGroup={handleAddGroup}
            onUpdateGroup={handleUpdateGroup}
            onDeleteGroup={handleDeleteGroup}
            onAddWeek={handleAddWeek}
            onDeleteWeek={handleDeleteWeek}
            onAddClass={handleAddClass}
            teachers={teachers}
            locations={locations}
            semester={selectedSemester}
          />

          {isSidebarOpen && (
            <Sidebar
              isOpen={isSidebarOpen}
              templates={templates}
              onClose={() => setIsSidebarOpen(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CourseScheduleEditor;
