import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';

const GroupPage = () => {
  const { facultyId, specialityId, courseId } = useParams(); // Получаем ID факультета, специальности и курса из URL
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    // Запрос к API для получения групп по курсу
    const fetchGroups = async () => {
      try {
        const response = await api.get(`/group_/all-by-foreign-key/course_id/${courseId}`);
        setGroups(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке групп', error);
      }
    };

    fetchGroups();
  }, [facultyId, specialityId, courseId]);

  return (
    <div>
      <h1>Выберите группу</h1>
      <ul>
        {groups && groups.map((group) => (
          <li key={group.id}>
            <span>{group.name}</span> {/* Здесь можно сделать переход на страницу расписания группы */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupPage;
