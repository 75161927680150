import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../assets/css/faculty.scss';

const FacultyPage = () => {
  const [faculties, setFaculties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchFaculties = async () => {
      try {
        const response = await api.get('/faculty');
        setFaculties(response.data.data);
      } catch (error) {
        console.error('Ошибка при загрузке факультетов', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFaculties();
  }, []);

  return (
    <div className='container'>
      <ul className='faculty-list'>
        {isLoading ? (
          Array.from({ length: 5 }).map((_, index) =>
            <li key={index} className='faculty-item' >
              <div className='faculty-link'>
                <Skeleton className='faculty-name' height={20} width={200} />
                <Skeleton className='faculty-logo skeleton' height={50} width={50} />
              </div>
            </li>)
        ) : (
          faculties && faculties.map((faculty) => (
            <li className='faculty-item' key={faculty.id}>
              <Link className='faculty-link' to={`/faculty/${faculty.id}/specialities`}>
                <span className='faculty-name'>{faculty.name}</span>
                {faculty.logo_url && <img className='faculty-logo' src={baseURL + faculty.logo_url} alt={faculty.name} height="50" />}
              </Link>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default FacultyPage;
