import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Select from 'react-select';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import customStyles from './css/customSelectStyles';
import paginationStyles from './css/paginationSelectStyles';

const CourseEditor = () => {
  const [faculties, setFaculties] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState('');
  const [selectedSpecialityId, setSelectedSpecialityId] = useState('');
  const [newCourse, setNewCourse] = useState({ name: '' });
  const [editCourseId, setEditCourseId] = useState(null);
  const [editCourse, setEditCourse] = useState({ name: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [loadingFaculties, setLoadingFaculties] = useState(true); // Состояние загрузки факультетов
  const [loadingSpecialities, setLoadingSpecialities] = useState(false); // Состояние загрузки специальностей
  const [loadingCourses, setLoadingCourses] = useState(false); // Состояние загрузки курсов
  const [searchActive, setSearchActive] = useState(false);
  const searchRef = useRef(null);
  const buttonRef = useRef(null);

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const fetchFaculties = useCallback(async () => {
    try {
      setLoadingFaculties(true);
      const response = await api.get('/faculty/all');
      setFaculties(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке факультетов', error);
    } finally {
      setLoadingFaculties(false);
    }
  }, []);

  useEffect(() => {
    fetchFaculties();
  }, [fetchFaculties]);

  const fetchSpecialities = useCallback(async (facultyId) => {
    try {
      setLoadingSpecialities(true);
      const response = await api.get(`/speciality/all-by-foreign-key/faculty_id/${facultyId}`);
      setSpecialities(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке специальностей', error);
    } finally {
      setLoadingSpecialities(false);
    }
  }, []);

  useEffect(() => {
    if (selectedFacultyId) {
      fetchSpecialities(selectedFacultyId);
    } else {
      setSpecialities([]);
      setSelectedSpecialityId('');
    }
  }, [selectedFacultyId, fetchSpecialities]);

  const fetchCourses = useCallback(async () => {
    try {
      setLoadingCourses(true);
      let req = `/course/by-foreign-key/speciality_id/${selectedSpecialityId}?page=${page}&limit=${limit}`;
      if (search) {
        req += `&searchFields=name&search=${search}`;
      }
      if (sort) req += `&sort=${sort}`;

      const response = await api.get(req);
      const data = response.data;
      setCourses(data.data || []);
      setTotalPages(data.pagination ? data.pagination.totalPages : 1);
      if (page > (data.pagination ? data.pagination.totalPages : 1)) {
        setPage(1);
      }
    } catch (error) {
      console.error('Ошибка при загрузке курсов', error);
      setCourses([]);
      setTotalPages(1);
    } finally {
      setLoadingCourses(false);
    }
  }, [selectedSpecialityId, page, limit, search, sort]);

  useEffect(() => {
    if (selectedSpecialityId) {
      fetchCourses();
    }
  }, [selectedSpecialityId, page, limit, search, fetchCourses]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const addCourse = async () => {
    try {
      await api.post('/course', { ...newCourse, speciality_id: selectedSpecialityId });
      toast.success('Курс добавлен!');
      fetchCourses();
      setNewCourse({ name: '' });
      setShowAddForm(false);
    } catch (error) {
      console.error('Ошибка при добавлении курса', error);
      toast.error('Ошибка при добавлении курса');
    }
  };

  const deleteCourse = async (id) => {
    try {
      await api.delete(`/course/${id}`);
      toast.success('Курс удален!');
      fetchCourses();
    } catch (error) {
      console.error('Ошибка при удалении курса', error);
      toast.error('Ошибка при удалении курса');
    }
  };

  const startEditCourse = (course) => {
    setEditCourseId(course.id);
    setEditCourse({ name: course.name });
  };

  const cancelEditCourse = () => {
    setEditCourseId(null);
    setEditCourse({ name: '' });
  };

  const updateCourse = async () => {
    try {
      await api.put(`/course/${editCourseId}`, { ...editCourse, speciality_id: selectedSpecialityId });
      toast.success('Курс обновлен!');
      fetchCourses();
      cancelEditCourse();
    } catch (error) {
      console.error('Ошибка при обновлении курса', error);
      toast.error('Ошибка при обновлении курса');
    }
  };

  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    searchElement.style.minWidth = '200px';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      searchElement.style.minWidth = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  const toggleAddForm = () => {
    setIsAnimating(true);

    if (!showAddForm) {
      setTimeout(() => {
        setShowAddForm(true);
        setIsAnimating(false);
      }, 150);
    } else {
      setIsClosing(true);
      setShowAddForm(false);
      setIsAnimating(false);
      setIsClosing(false);
    }
  };

  useEffect(() => {
    const updateModalPosition = () => {
      if (buttonRef.current && showAddForm) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const modalContent = document.querySelector('.editor-add-modal');

        if (modalContent) {
          modalContent.style.top = `${buttonRect.bottom}px`;
          modalContent.style.left = `${buttonRect.left}px`;
        }
      }
    };

    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    updateModalPosition();

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, [showAddForm]);

  useEffect(() => { if (!selectedSpecialityId) setSearch(''); }, [selectedSpecialityId]);

  return (
    <div className='container'>
      <div className='selects-wrapper'>
        <label className='select-label'>
          <span className='select-title'>Факультет</span>
          {loadingFaculties ? (
            <Skeleton height={38} width={200} />
          ) : (
            <Select
              styles={customStyles}
              value={faculties.find(faculty => faculty.value === selectedFacultyId)}
              onChange={(selectedOption) => setSelectedFacultyId(selectedOption ? selectedOption.value : '')}
              options={faculties.map(faculty => ({ value: faculty.id, label: faculty.name }))}
              placeholder="Выберите факультет"
              noOptionsMessage={() => "Факультеты не найдены"}
              isClearable
            />
          )}
        </label>
        {selectedFacultyId && (
          <label className='select-label'>
            <span className='select-title'>Специальность</span>
            {loadingSpecialities ? (
              <Skeleton height={38} width={200} />
            ) : (
              <Select
                styles={customStyles}
                value={specialities.find(speciality => speciality.value === selectedSpecialityId)}
                onChange={(selectedOption) => setSelectedSpecialityId(selectedOption ? selectedOption.value : '')}
                options={specialities.map(speciality => ({ value: speciality.id, label: `${speciality.identifier} ${speciality.name}` }))}
                placeholder="Выберите специальность"
                noOptionsMessage={() => "Специальности не найдены"}
                isClearable
              />
            )}
          </label>
        )}
      </div>
      {selectedSpecialityId && (<>
        <div className='actions-list'>
          <button
            ref={buttonRef}
            onClick={toggleAddForm}
            className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
          >
            <span className="add-entity-button-text">
              <span className="text-add">Добавить курс</span>
              <span className="text-cancel">Отмена</span>
            </span>
            <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
          </button>
          <Modal
            isOpen={showAddForm}
            onRequestClose={toggleAddForm}
            contentLabel="Добавить курс"
            className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
            overlayClassName="editor-add-overlay"
            closeTimeoutMS={300}
            style={{
              content: {
                top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
                left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
                transform: 'translate(0, 0)',
              },
            }}
          >
            <h3>Добавить курс</h3>
            <div className='editor-add-modal-form'>
              <div className='editor-add-modal-form-input-wrapper'>
                <input
                  type="text"
                  placeholder="Название курса"
                  value={newCourse.name}
                  onChange={(e) => setNewCourse({ ...newCourse, name: e.target.value })}
                />
                {newCourse.name && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewCourse({ ...newCourse, name: '' })}><IonIcon icon={closeOutline} /></button>}
              </div>
              <button disabled={!newCourse.name} onClick={addCourse} className='editor-add-modal-form-add-button'><span className='editor-add-modal-form-add-button-text'>Добавить</span><IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} /></button>
            </div>
          </Modal>
          <div className='arrange-list'>
            <button
              className='search-bar-button'
              onClick={toggleSearch}
              onMouseDown={(e) => e.preventDefault()}
            >
              <IonIcon className='search-bar-icon' icon={searchOutline} />
            </button>
            <input
              type="text"
              placeholder="Поиск"
              className='search-bar'
              value={search}
              ref={searchRef}
              onChange={handleSearchChange}
              onBlur={handleSearchBlur}
            />
            {searchActive && search && (
              <button onClick={() => { setSearch(''); searchRef.current.focus() }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
            )}
            <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('name ASC'): return 'name DESC'; case ('name DESC'): return ''; default: return 'name ASC' } })}>А-Я
              {sort === 'name ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
              {sort === 'name DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
              {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
            </button>
            <button className='fetch-entities' onClick={fetchCourses}><IonIcon icon={checkmarkOutline} /></button>
          </div>
        </div>
        <div className='content-area'>
          <ul className='entity-list'>
            <li className='entity-header course'>
              <span>Название курса</span>
              <span className='entity-header-actions'>Действия</span>
            </li>
            {loadingCourses ? (
              Array(10).fill().map((_, index) => (
                <li key={index} className="entity-item course">
                  <span className='entity-item-name'><Skeleton width={200} /></span>
                  <Skeleton width={30} height={30} style={{ marginRight: 10 }} />
                  <Skeleton width={30} height={30} />
                </li>
              ))
            ) : (
              courses.map(course => (
                <li className={`entity-item course ${course.id === editCourseId ? 'editing' : ''}`} key={course.id}>
                  {course.id === editCourseId ? (
                    <>
                      <input
                        type="text"
                        value={editCourse.name}
                        onChange={(e) => setEditCourse({ ...editCourse, name: e.target.value })}
                      />
                      <button className='entity-item-button done' onClick={updateCourse}><IonIcon icon={checkmarkOutline} /></button>
                      <button className='entity-item-button cancel' onClick={cancelEditCourse}><IonIcon icon={closeOutline} /></button>
                    </>
                  ) : (
                    <>
                      <span className='entity-item-name'>{course.name}</span>
                      <button className='entity-item-button edit' onClick={() => startEditCourse(course)}><IonIcon icon={createOutline} /></button>
                      <button className='entity-item-button delete' onClick={() => deleteCourse(course.id)}><IonIcon icon={trashOutline} /></button>
                    </>
                  )}
                </li>
              ))
            )}
          </ul>
          <div className='page-navigation'>
            <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
            <span>Страница {page} из {totalPages}</span>
            <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
          </div>
          <div className='page-pagination'>
            <label>Количество строк на странице</label>
            <Select
              styles={paginationStyles}
              value={paginationOptions.find(option => option.value === limit)}
              onChange={(selectedOption) => { setLimit(selectedOption.value); }}
              options={paginationOptions}
              isSearchable={false}
            />
          </div>
        </div>
      </>)}
    </div>
  );
};

export default CourseEditor;
