import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Select from 'react-select';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import customStyles from './css/customSelectStyles';
import paginationStyles from './css/paginationSelectStyles';

import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/green.css";
import Russian from '../../../../assets/locales/react-multi-date-picker/russian_ru';

const SemesterEditor = () => {
  const [faculties, setFaculties] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState('');
  const [selectedSpecialityId, setSelectedSpecialityId] = useState('');
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [newSemester, setNewSemester] = useState({
    name: '',
    start_date: new Date().toLocaleDateString('ru-RU'),
    end_date: new Date().toLocaleDateString('ru-RU')
  });
  const [editSemesterId, setEditSemesterId] = useState(null);
  const [editSemester, setEditSemester] = useState({
    name: '',
    start_date: new Date().toLocaleDateString('ru-RU'),
    end_date: new Date().toLocaleDateString('ru-RU')
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [loadingFaculties, setLoadingFaculties] = useState(true);
  const [loadingSpecialities, setLoadingSpecialities] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingSemesters, setLoadingSemesters] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const searchRef = useRef(null);
  const buttonRef = useRef(null);

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const fetchFaculties = useCallback(async () => {
    try {
      setLoadingFaculties(true);
      const response = await api.get('/faculty/all');
      setFaculties(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке факультетов', error);
    } finally {
      setLoadingFaculties(false);
    }
  }, []);

  useEffect(() => {
    fetchFaculties();
  }, [fetchFaculties]);

  const fetchSpecialities = useCallback(async (facultyId) => {
    try {
      setLoadingSpecialities(true);
      const response = await api.get(`/speciality/all-by-foreign-key/faculty_id/${facultyId}`);
      setSpecialities(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке специальностей', error);
    } finally {
      setLoadingSpecialities(false);
    }
  }, []);

  useEffect(() => {
    if (selectedFacultyId) {
      fetchSpecialities(selectedFacultyId);
    } else {
      setSpecialities([]);
      setSelectedSpecialityId('');
    }
  }, [selectedFacultyId, fetchSpecialities]);

  const fetchCourses = useCallback(async (specialityId) => {
    try {
      setLoadingCourses(true);
      const response = await api.get(`/course/all-by-foreign-key/speciality_id/${specialityId}`);
      setCourses(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке курсов', error);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  useEffect(() => {
    if (selectedSpecialityId) {
      fetchCourses(selectedSpecialityId);
    } else {
      setCourses([]);
      setSelectedCourseId('');
    }
  }, [selectedSpecialityId, fetchCourses]);

  const fetchSemesters = useCallback(async () => {
    try {
      setLoadingSemesters(true);
      let req = `/semester/by-foreign-key/course_id/${selectedCourseId}?page=${page}&limit=${limit}`;
      if (search) {
        req += `&searchFields=name&search=${search}`;
      }
      if (sort) req += `&sort=${sort}`;

      const response = await api.get(req);
      const data = response.data;
      setSemesters(data.data || []);
      setTotalPages(data.pagination ? data.pagination.totalPages : 1);
      if (page > (data.pagination ? data.pagination.totalPages : 1)) {
        setPage(1);
      }
    } catch (error) {
      console.error('Ошибка при загрузке семестров', error);
      setSemesters([]);
      setTotalPages(1);
    } finally {
      setLoadingSemesters(false);
    }
  }, [selectedCourseId, page, limit, search, sort]);

  useEffect(() => {
    if (selectedCourseId) {
      fetchSemesters();
    }
  }, [selectedCourseId, page, limit, search, fetchSemesters]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const formatToISO = (dateString) => {
    const parts = dateString.split('.');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    return `${year}-${month}-${day}`;
  };

  const addSemester = async () => {
    try {
      const payload = {
        ...newSemester,
        start_date: formatToISO(newSemester.start_date),
        end_date: formatToISO(newSemester.end_date),
        course_id: selectedCourseId
      };
      await api.post('/semester', payload);
      toast.success('Семестр добавлен!');
      fetchSemesters();
      setNewSemester({ name: '', start_date: new Date().toLocaleDateString('ru-RU'), end_date: new Date().toLocaleDateString('ru-RU') });
      setShowAddForm(false);
    } catch (error) {
      console.error('Ошибка при добавлении семестра', error);
      toast.error('Ошибка при добавлении семестра');
    }
  };

  const deleteSemester = async (id) => {
    try {
      await api.delete(`/semester/${id}`);
      toast.success('Семестр удален!');
      fetchSemesters();
    } catch (error) {
      console.error('Ошибка при удалении семестра', error);
      toast.error('Ошибка при удалении семестра');
    }
  };

  const startEditSemester = (semester) => {
    setEditSemesterId(semester.id);
    setEditSemester({
      name: semester.name,
      start_date: new Date(semester.start_date).toLocaleDateString('ru-RU'),
      end_date: new Date(semester.end_date).toLocaleDateString('ru-RU')
    });
  };

  const cancelEditSemester = () => {
    setEditSemesterId(null);
    setEditSemester({ name: '', start_date: new Date().toLocaleDateString('ru-RU'), end_date: new Date().toLocaleDateString('ru-RU') });
  };

  const updateSemester = async () => {
    try {
      const payload = {
        ...editSemester,
        start_date: formatToISO(editSemester.start_date),
        end_date: formatToISO(editSemester.end_date),
        course_id: selectedCourseId
      };
      await api.put(`/semester/${editSemesterId}`, payload);
      toast.success('Семестр обновлен!');
      fetchSemesters();
      cancelEditSemester();
    } catch (error) {
      console.error('Ошибка при обновлении семестра', error);
      toast.error('Ошибка при обновлении семестра');
    }
  };

  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  const toggleAddForm = () => {
    setIsAnimating(true);

    if (!showAddForm) {
      setTimeout(() => {
        setShowAddForm(true);
        setIsAnimating(false);
      }, 150);
    } else {
      setIsClosing(true);
      setShowAddForm(false);
      setIsAnimating(false);
      setIsClosing(false);
    }
  };

  useEffect(() => {
    const updateModalPosition = () => {
      if (buttonRef.current && showAddForm) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const modalContent = document.querySelector('.editor-add-modal');

        if (modalContent) {
          modalContent.style.top = `${buttonRect.bottom}px`;
          modalContent.style.left = `${buttonRect.left}px`;
        }
      }
    };

    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    updateModalPosition();

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, [showAddForm]);

  useEffect(() => { if (!selectedCourseId) setSearch(''); }, [selectedCourseId]);

  return (
    <div className='container'>
      <div className='selects-wrapper'>
        <label className='select-label'>
          <span className='select-title'>Факультет</span>
          {loadingFaculties ? (
            <Skeleton height={38} width={200} />
          ) : (
            <Select
              styles={customStyles}
              value={faculties.find(faculty => faculty.value === selectedFacultyId)}
              onChange={(selectedOption) => setSelectedFacultyId(selectedOption ? selectedOption.value : '')}
              options={faculties.map(faculty => ({ value: faculty.id, label: faculty.name }))}
              placeholder="Выберите факультет"
              noOptionsMessage={() => "Факультеты не найдены"}
              isClearable
            />
          )}
        </label>
        {selectedFacultyId && (
          <label className='select-label'>
            <span className='select-title'>Специальность</span>
            {loadingSpecialities ? (
              <Skeleton height={38} width={200} />
            ) : (
              <Select
                styles={customStyles}
                value={specialities.find(speciality => speciality.value === selectedSpecialityId)}
                onChange={(selectedOption) => setSelectedSpecialityId(selectedOption ? selectedOption.value : '')}
                options={specialities.map(speciality => ({ value: speciality.id, label: `${speciality.identifier} ${speciality.name}` }))}
                placeholder="Выберите специальность"
                noOptionsMessage={() => "Специальности не найдены"}
                isClearable
              />
            )}
          </label>
        )}
        {selectedSpecialityId && (
          <label className='select-label'>
            <span className='select-title'>Курс</span>
            {loadingCourses ? (
              <Skeleton height={38} width={200} />
            ) : (
              <Select
                styles={customStyles}
                value={courses.find(course => course.value === selectedCourseId)}
                onChange={(selectedOption) => setSelectedCourseId(selectedOption ? selectedOption.value : '')}
                options={courses.map(course => ({ value: course.id, label: course.name }))}
                placeholder="Выберите курс"
                noOptionsMessage={() => "Курсы не найдены"}
                isClearable
              />
            )}
          </label>
        )}
      </div>
      {selectedCourseId && (<>
        <div className='actions-list'>
          <button
            ref={buttonRef}
            onClick={toggleAddForm}
            className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
          >
            <span className="add-entity-button-text">
              <span className="text-add">Добавить семестр</span>
              <span className="text-cancel">Отмена</span>
            </span>
            <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
          </button>
          <Modal
            isOpen={showAddForm}
            onRequestClose={toggleAddForm}
            contentLabel="Добавить семестр"
            className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
            overlayClassName="editor-add-overlay"
            closeTimeoutMS={300}
            style={{
              content: {
                top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
                left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
                transform: 'translate(0, 0)',
              },
            }}
          >
            <h3>Добавить семестр</h3>
            <div className='editor-add-modal-form'>
              <div className='editor-add-modal-form-input-wrapper'>
                <input
                  type="text"
                  placeholder="Название семестра"
                  value={newSemester.name}
                  onChange={(e) => setNewSemester({ ...newSemester, name: e.target.value })}
                />
                {newSemester.name && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewSemester({ ...newSemester, name: '' })}><IonIcon icon={closeOutline} /></button>}
              </div>
              <div className='editor-add-modal-form-input-wrapper date-picker'>
              <span className='field-name'>Дата начала семестра</span>
                <DatePicker
                  value={newSemester.start_date}
                  onChange={(date) => setNewSemester({ ...newSemester, start_date: date.format("DD.MM.YYYY") })}
                  format="DD.MM.YYYY"
                  locale={Russian()}
                  weekStartDayIndex={1}
                />
              </div>
              <div className='editor-add-modal-form-input-wrapper date-picker'>
                <span className='field-name'> Дата конца семестра</span>
                <DatePicker
                  value={newSemester.end_date}
                  onChange={(date) => setNewSemester({ ...newSemester, end_date: date.format("DD.MM.YYYY") })}
                  format="DD.MM.YYYY"
                  locale={Russian()}
                  weekStartDayIndex={1}
                />
              </div>
              <button disabled={!newSemester.name || !newSemester.start_date || !newSemester.end_date} onClick={addSemester} className='editor-add-modal-form-add-button'><span className='editor-add-modal-form-add-button-text'>Добавить</span><IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} /></button>
            </div>
          </Modal>
          <div className='arrange-list'>
            <button
              className='search-bar-button'
              onClick={toggleSearch}
              onMouseDown={(e) => e.preventDefault()}
            >
              <IonIcon className='search-bar-icon' icon={searchOutline} />
            </button>
            <input
              type="text"
              placeholder="Поиск"
              className='search-bar'
              value={search}
              ref={searchRef}
              onChange={handleSearchChange}
              onBlur={handleSearchBlur}
            />
            {searchActive && search && (
              <button onClick={() => { setSearch(''); searchRef.current.focus() }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
            )}
            <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('name ASC'): return 'name DESC'; case ('name DESC'): return ''; default: return 'name ASC' } })}>Название
              {sort === 'name ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
              {sort === 'name DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
              {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
            </button>
            <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('start_date ASC'): return 'start_date DESC'; case ('start_date DESC'): return ''; default: return 'start_date ASC' } })}>Дата начала
              {sort === 'start_date ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
              {sort === 'start_date DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
              {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
            </button>
            <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('end_date ASC'): return 'end_date DESC'; case ('end_date DESC'): return ''; default: return 'end_date ASC' } })}>Дата окончания
              {sort === 'end_date ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
              {sort === 'end_date DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
              {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
            </button>
            <button className='fetch-entities' onClick={fetchSemesters}><IonIcon icon={checkmarkOutline} /></button>
          </div>
        </div>
        <div className='content-area'>
          <ul className='entity-list'>
            <li className='entity-header semester'>
              <span>Название</span>
              <span>Дата начала</span>
              <span>Дата окончания</span>
              <span className='entity-header-actions'>Действия</span>
            </li>
            {loadingSemesters ? (
              Array(10).fill().map((_, index) => (
                <li key={index} className="entity-item semester">
                  <span className='entity-item-name'><Skeleton width={150} /></span>
                  <span><Skeleton width={100} /></span>
                  <span><Skeleton width={100} /></span>
                  <Skeleton width={30} height={30} />
                  <Skeleton width={30} height={30} />
                </li>
              ))
            ) : (
              semesters.map(semester => (
                <li className={`entity-item semester ${semester.id === editSemesterId ? 'editing' : ''}`} key={semester.id}>
                  {semester.id === editSemesterId ? (
                    <>
                      <input
                        type="text"
                        value={editSemester.name}
                        onChange={(e) => setEditSemester({ ...editSemester, name: e.target.value })}
                      />
                      <DatePicker
                        value={editSemester.start_date}
                        onChange={(date) => setEditSemester({ ...editSemester, start_date: date.format("DD.MM.YYYY") })}
                        format="DD.MM.YYYY"
                        locale={Russian()}
                        weekStartDayIndex={1}
                      />
                      <DatePicker
                        value={editSemester.end_date}
                        onChange={(date) => setEditSemester({ ...editSemester, end_date: date.format("DD.MM.YYYY") })}
                        format="DD.MM.YYYY"
                        locale={Russian()}
                        weekStartDayIndex={1}
                      />
                      <button className='entity-item-button done' onClick={updateSemester}><IonIcon icon={checkmarkOutline} /></button>
                      <button className='entity-item-button cancel' onClick={cancelEditSemester}><IonIcon icon={closeOutline} /></button>
                    </>
                  ) : (
                    <>
                      <span className='entity-item-name'>{semester.name}</span>
                      <span>{new Date(semester.start_date).toLocaleDateString('ru-RU')}</span>
                      <span>{new Date(semester.end_date).toLocaleDateString('ru-RU')}</span>
                      <button className='entity-item-button edit' onClick={() => startEditSemester(semester)}><IonIcon icon={createOutline} /></button>
                      <button className='entity-item-button delete' onClick={() => deleteSemester(semester.id)}><IonIcon icon={trashOutline} /></button>
                    </>
                  )}
                </li>
              ))
            )}
          </ul>
          <div className='page-navigation'>
            <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
            <span>Страница {page} из {totalPages}</span>
            <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
          </div>
          <div className='page-pagination'>
            <label>Количество строк на странице</label>
            <Select
              styles={paginationStyles}
              value={paginationOptions.find(option => option.value === limit)}
              onChange={(selectedOption) => { setLimit(selectedOption.value) }}
              options={paginationOptions}
              isSearchable={false}
            />
          </div>

        </div>
      </>)}
    </div>
  );
};

export default SemesterEditor;
