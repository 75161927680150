// src/pages/NotFound.js
import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from '../assets/animations/404.json';
import '../assets/css/404.scss';

const NotFound = () => {

  const notFoundRef = useRef(null);

  const handleHeight = useCallback(() => {
    if (notFoundRef.current) {
      const additionalHeight = notFoundRef.current.getBoundingClientRect().top;
      notFoundRef.current.style.minHeight = `${window.innerHeight - additionalHeight}px`;
    }
  }, [notFoundRef]);

  useEffect(() => { handleHeight() }, [handleHeight]);

  useEffect(() => {
    window.addEventListener('resize', handleHeight);
    return () => {
      window.removeEventListener('resize', handleHeight);
    };
  }, [handleHeight]);

  return (
    <div className='container' >
      <h1 className='visually-hidden'>Page Not Found</h1>
      <div className='not-found-window' ref={notFoundRef}>
        <Lottie className='not-found-image' animationData={animationData} loop={true} />
        <div className='not-found-description'>
          <span className='not-found-title'>Страница не найдена</span>
          <p className='not-found-info'>Вы нашли страницу, которой не существует в веб расписании. Такое может случиться, если вы перешли по ссылке на что-то, что уже удалено. Или же ссылка изначально была неверной.</p>
          <Link className='not-found-home' to={'/'}>На главную</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
