import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../services/api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../assets/css/speciality.scss';

const SpecialityPage = () => {
  const { facultyId } = useParams(); // Получаем ID факультета из URL
  const [loadingFaculty, setLoadingFaculty] = useState(false);
  const [faculty, setFaculty] = useState({ id: 0, name: '', logo_url: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [specialities, setSpecialities] = useState({
    Бакалавриат: [],
    Специалитет: [],
    Магистратура: [],
    Ординатура: [],
  });

  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        setLoadingFaculty(true);
        const response = await api.get(`/faculty/all-by-foreign-key/id/${facultyId}`);
        setFaculty(response.data[0]);
      } catch (error) {
        console.error('Ошибка при загрузке данных о факультете', error);
      } finally {
        setLoadingFaculty(false);
      }
    }

    fetchFaculty();

  }, [facultyId]);

  useEffect(() => {
    // Запрос к API для получения специальностей по факультету
    const fetchSpecialities = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`/speciality/all-by-foreign-key/faculty_id/${facultyId}`);
        const groupedSpecialities = groupSpecialitiesByProgramType(response.data);
        setSpecialities(groupedSpecialities);
      } catch (error) {
        console.error('Ошибка при загрузке специальностей', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSpecialities();
  }, [facultyId]);

  // Функция для группировки специальностей по типу программы
  const groupSpecialitiesByProgramType = (specialities) => {
    const grouped = {
      Бакалавриат: [],
      Специалитет: [],
      Магистратура: [],
      Ординатура: [],
    };

    specialities.forEach(speciality => {
      const { program } = speciality;
      if (grouped[program]) {
        grouped[program].push(speciality);
      }
    });

    return grouped;
  };

  const renderSpecialityList = (specialities, programType) => (
    <>
      <h3 className='speciality-program'>{programType}</h3>
      <ul className='speciality-list'>
        {specialities.map(speciality => (
          <li className='speciality-item' key={speciality.id}>
            <Link className='speciality-link' to={`/faculty/${facultyId}/speciality/${speciality.id}/courses`}>
              <span className='speciality-identifier'>{speciality.identifier}</span>
              <span className='speciality-name'>{speciality.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <div className='container'>
      <div className='speciality-titles'>
        {loadingFaculty ?
          (<Skeleton width={160} height={18} />) :
          (<h1 className='speciality-faculty-name' to="/">{faculty.name}</h1>)
        }
      </div>
      {isLoading ? (
        <ul className='speciality-list'>
          {Array.from({ length: 4 }).map((_, index) => (
            <li key={index} className='speciality-item'>
              <div className='speciality-link'>
                <span className='speciality-identifier'>
                  <Skeleton width={18} height={100} />
                </span>
                <span className='speciality-name'>
                  <Skeleton width={150} height={20} />
                </span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <>
          {specialities.Бакалавриат.length > 0 && renderSpecialityList(specialities.Бакалавриат, 'Бакалавриат')}
          {specialities.Специалитет.length > 0 && renderSpecialityList(specialities.Специалитет, 'Специалитет')}
          {specialities.Магистратура.length > 0 && renderSpecialityList(specialities.Магистратура, 'Магистратура')}
          {specialities.Ординатура.length > 0 && renderSpecialityList(specialities.Ординатура, 'Ординатура')}
        </>
      )}
    </div>
  );
};

export default SpecialityPage;
