import React, { useState, useRef, useCallback } from 'react';
import Modal from './Modal';
import GroupActions from './GroupActions';
import WeekHeader from './WeekHeader';
import DayRow from './DayRow';
import './ScheduleTable.css';
import { IonIcon } from '@ionic/react';
import { checkmarkOutline, addOutline } from 'ionicons/icons';

const useModal = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [editGroupName, setEditGroupName] = useState('');
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [editModalPosition, setEditModalPosition] = useState({ top: 0, left: 0 });

  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setNewGroupName('');
  };
  const openEditModal = (group, event, container) => {
    setEditGroupName(group.name);
    setCurrentGroupId(group.id);
    const rect = container.getBoundingClientRect();
    setEditModalPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditGroupName('');
    setCurrentGroupId(null);
  };

  return {
    isAddModalOpen, newGroupName, setNewGroupName,
    isEditModalOpen, editGroupName, setEditGroupName, currentGroupId,
    editModalPosition, openAddModal, closeAddModal,
    openEditModal, closeEditModal
  };
};

const ScheduleTable = ({ groups, foe, weeks, onAddGroup, onUpdateGroup, onDeleteGroup, onAddWeek, onDeleteWeek, onAddClass, teachers, locations, semester }) => {
  const {
    isAddModalOpen, newGroupName, setNewGroupName,
    isEditModalOpen, editGroupName, setEditGroupName, currentGroupId,
    editModalPosition, openAddModal, closeAddModal,
    openEditModal, closeEditModal
  } = useModal();

  const debug = false;

  const [isEditing, setIsEditing] = useState(false);
  const [schedule, setSchedule] = useState({});
  const [resizing, setResizing] = useState({ weekIndex: null, dayIndex: null, groupIndex: null, side: null, lastIndex: null, lessonIndex: null, boundaries: {} });
  const [cursorCell, _setCursorCell] = useState(null);
  const resizingRef = useRef({ weekIndex: null, dayIndex: null, groupIndex: null, side: null });
  const cursorCellRef = useRef(null);

  const setCursorCell = (value) => {
    _setCursorCell(value);
    cursorCellRef.current = value;
  };

  const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

  const handleUpdateClass = (weekIndex, dayIndex, groupIndex, lessonIndex, updatedDetails) => {
    setSchedule(prevSchedule => {
      const newSchedule = { ...prevSchedule };
      const cellKey = `${weekIndex}-${dayIndex}`;
      newSchedule[cellKey][groupIndex][lessonIndex] = {
        ...newSchedule[cellKey][groupIndex][lessonIndex],
        ...updatedDetails
      };
      return newSchedule;
    });
  };

  const handleDropClass = (template, weekIndex, dayIndex, groupIndex, lessonIndex) => {
    const week = weeks[weekIndex];
    const day = daysOfWeek[dayIndex];
    const group = groups[groupIndex];
    if (!week || !day || !group) {
      console.error('Invalid drop coordinates:', { week, day, group });
      return;
    }

    const cellKey = `${weekIndex}-${dayIndex}`;
    setSchedule((prevSchedule) => {
      const updatedSchedule = { ...prevSchedule };
      if (!updatedSchedule[cellKey]) updatedSchedule[cellKey] = { lessons: { max: 1, maxGroups: [groupIndex] } };
      else if (lessonIndex + 1 > updatedSchedule[cellKey].lessons.max) updatedSchedule[cellKey].lessons = { max: lessonIndex + 1, maxGroups: [groupIndex] };
      else if (lessonIndex + 1 === updatedSchedule[cellKey].lessons.max) updatedSchedule[cellKey].lessons.maxGroups.push(groupIndex);
      if (!updatedSchedule[cellKey][groupIndex]) updatedSchedule[cellKey][groupIndex] = {};
      const templateWrapped = {
        ...Object.fromEntries(
          Object.entries(template).map(([key, value]) => [`template${key.charAt(0).toUpperCase() + key.slice(1)}`, value])
        ),
        groupId: groups[groupIndex].id,
        weekNumber: weeks[weekIndex].number,
        day: daysOfWeek[dayIndex],
        place: '',
        teacher: '',
        dates: [],
        cyclic: true,
        sharedGroups: [],
      };

      onAddClass({
        ...template,
        groupId: groups[groupIndex].id,
        weekNumber: weeks[weekIndex].number,
        day: daysOfWeek[dayIndex],
        lessonNumber: updatedSchedule[cellKey][groupIndex].length - 1,
        place: '',
        teacher: '',
        dates: [],
        cyclic: true
      });

      updatedSchedule[cellKey][groupIndex][lessonIndex] = templateWrapped;
      return updatedSchedule;
    });
  };

  const handleRemoveClass = useCallback((weekIndex, dayIndex, groupIndex, lessonIndex) => {
    const cellKey = `${weekIndex}-${dayIndex}`;
    setSchedule((prevSchedule) => {
      const updatedSchedule = { ...prevSchedule };

      if (updatedSchedule[cellKey]?.[groupIndex]?.[lessonIndex]?.sharedGroups?.length > 0) {
        updatedSchedule[cellKey][groupIndex][lessonIndex].sharedGroups.forEach(
          (group) => {
            if (updatedSchedule[cellKey]?.[group]?.[lessonIndex]) {
              delete updatedSchedule[cellKey][group][lessonIndex];
              if (Object.keys(updatedSchedule[cellKey][group]).length === 0) {
                delete updatedSchedule[cellKey][group];
              }
              if (updatedSchedule[cellKey].lessons.maxGroups.includes(group))
                updatedSchedule[cellKey].lessons.maxGroups = updatedSchedule[cellKey].lessons.maxGroups.filter(gr => gr !== group);
            }
          }
        );
      }

      if (updatedSchedule[cellKey]?.[groupIndex]) {
        delete updatedSchedule[cellKey][groupIndex][lessonIndex];
        if (Object.keys(updatedSchedule[cellKey][groupIndex]).length === 0) {
          delete updatedSchedule[cellKey][groupIndex];
        }
      }

      if (updatedSchedule[cellKey]?.lessons?.maxGroups?.includes(groupIndex)) {
        const isStillMax = Object.keys(updatedSchedule[cellKey][groupIndex] || {}).length === updatedSchedule[cellKey].lessons.max;
        if (!isStillMax) {
          updatedSchedule[cellKey].lessons.maxGroups = updatedSchedule[cellKey].lessons.maxGroups
            .filter(group => (group !== groupIndex));
        }
        if (updatedSchedule[cellKey].lessons.maxGroups.length === 0) {
          updatedSchedule[cellKey].lessons = Object.keys(updatedSchedule[cellKey])
            .filter(group => group !== 'lessons')
            .reduce((maxGroups, group) => {
              const groupInt = parseInt(group);
              const maxInGroup = Math.max(...Object.keys(updatedSchedule[cellKey][groupInt]).map(Number)) + 1;
              if (Object.keys(maxGroups).length === 0) maxGroups = { max: maxInGroup, maxGroups: [groupInt] }
              else if (maxGroups.max < maxInGroup)
                maxGroups = { max: maxInGroup, maxGroups: [groupInt] };
              else if (maxGroups.max === maxInGroup) {
                maxGroups.maxGroups.push(groupInt);
              }
              return maxGroups;
            }, {});
        }
      }

      // Очищаем пустой день
      if (Object.keys(updatedSchedule[cellKey]).length === 1 && updatedSchedule[cellKey].lessons) {
        delete updatedSchedule[cellKey];
      }

      return updatedSchedule;
    });
  }, []);

  const handleMoveClass = (item, weekIndex, dayIndex, groupIndex, lessonIndex) => {
    const { weekIndex: sourceWeekIndex, dayIndex: sourceDayIndex, groupIndex: sourceGroupIndex, lessonIndex: sourceLessonIndex } = item;
    const sourceCellKey = `${sourceWeekIndex}-${sourceDayIndex}`;
    const targetCellKey = `${weekIndex}-${dayIndex}`;

    setSchedule((prevSchedule) => {
      const updatedSchedule = { ...prevSchedule };

      if (!updatedSchedule[targetCellKey]) updatedSchedule[targetCellKey] = { lessons: { max: sourceLessonIndex, maxGroups: [groupIndex] } };
      if (!updatedSchedule[targetCellKey][groupIndex]) updatedSchedule[targetCellKey][groupIndex] = {};
      if (!updatedSchedule[targetCellKey][groupIndex][lessonIndex]) updatedSchedule[targetCellKey][groupIndex][lessonIndex] = {};
      if (updatedSchedule[targetCellKey].lessons.max < lessonIndex + 1) updatedSchedule[targetCellKey].lessons = { max: lessonIndex + 1, maxGroups: [groupIndex] };
      if (updatedSchedule[targetCellKey].lessons.max === lessonIndex + 1 && !updatedSchedule[targetCellKey].lessons.maxGroups.includes(groupIndex)) updatedSchedule[targetCellKey].lessons.maxGroups.push(groupIndex);


      const movedLesson = {
        ...prevSchedule[sourceCellKey][sourceGroupIndex][sourceLessonIndex],
        weekNumber: weeks[weekIndex].number,
        day: daysOfWeek[dayIndex],
        groupId: groups[groupIndex].id,
        sharedGroups: [],
      };

      updatedSchedule[targetCellKey][groupIndex][lessonIndex] = movedLesson;

      return updatedSchedule;
    });
    handleRemoveClass(sourceWeekIndex, sourceDayIndex, sourceGroupIndex, sourceLessonIndex);
  };

  const handleAddGroup = () => {
    if (newGroupName) {
      onAddGroup({ name: newGroupName, form_of_education: foe });
      closeAddModal();
    }
  };

  const handleEditGroup = () => {
    if (editGroupName && currentGroupId !== null) {
      onUpdateGroup({ id: currentGroupId, name: editGroupName });
      closeEditModal();
    }
  };

  const handleDeleteGroup = (groupId) => {
    onDeleteGroup(groupId);
  }

  const handleAddWeek = () => onAddWeek({ number: weeks.length + 1 });

  const handleDeleteWeek = (week) => {
    onDeleteWeek(week);
  };

  const mergeClass = (currentResizing, currentCursorCell, schedule) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = { ...prevSchedule };
      const cellKey = `${currentResizing.weekIndex}-${currentResizing.dayIndex}`;
      const lessonIndex = currentResizing.lessonIndex;
      const startCell = currentResizing.side === "right" ? currentResizing.groupIndex : currentCursorCell;
      const endCell = currentResizing.side === "right" ? currentCursorCell :
        schedule.sharedGroups.length === 0 ? currentResizing.groupIndex : schedule.sharedGroups[schedule.sharedGroups.length - 1];

      if (schedule.sharedGroups.length > 0) {
        schedule.sharedGroups.forEach(group => delete updatedSchedule[cellKey][group][lessonIndex]);
      }
      delete updatedSchedule[cellKey][currentResizing.groupIndex][lessonIndex];

      for (let i = startCell; i <= endCell; i++) {
        if (!updatedSchedule[cellKey][i]) updatedSchedule[cellKey][i] = {};
        updatedSchedule[cellKey][i][lessonIndex] = { shared: true, sharedGroup: startCell };
      }

      if (updatedSchedule[cellKey].lessons.maxGroups.includes(currentResizing.groupIndex)) {
        const oldGroups = [currentResizing.groupIndex, ...schedule.sharedGroups];
        const newGroups = Array.from({ length: endCell + 1 - startCell }, (v, i) => i + startCell);

        updatedSchedule[cellKey].lessons.maxGroups = updatedSchedule[cellKey].lessons.maxGroups.filter(group => !oldGroups.includes(group) || newGroups.includes(group));

        newGroups.forEach(group => {
          if (!oldGroups.includes(group) && !updatedSchedule[cellKey].lessons.maxGroups.includes(group)) {
            updatedSchedule[cellKey].lessons.maxGroups.push(group);
          }
        });

      }

      updatedSchedule[cellKey][startCell][lessonIndex] = {
        ...schedule,
        sharedGroups: Array.from({ length: endCell - startCell }, (_, i) => startCell + 1 + i)
      };

      return updatedSchedule;
    });
  };

  const getLastIndex = (cellKey, groupIndex, lessonIndex) => {
    const targetSheduleRow = schedule[cellKey];

    if (targetSheduleRow && targetSheduleRow[groupIndex] && targetSheduleRow[groupIndex][lessonIndex]) {
      const targetCell = targetSheduleRow[groupIndex][lessonIndex];
      if (targetCell)
        if (targetCell.shared)
          return targetSheduleRow[targetCell.sharedGroup][lessonIndex].sharedGroups.at(-1);
        else if (targetCell.sharedGroups.length > 0)
          return targetCell.sharedGroups.at(-1);
    }
    return groupIndex;
  }

  const getBoundaries = (weekIndex, dayIndex, groupIndex, lastIndex, lessonIndex) => {
    const cellKey = `${weekIndex}-${dayIndex}`;
    const targetScheduleDay = schedule[cellKey];
    if (!targetScheduleDay) return { left: 0, right: groups.length - 1 };

    let leftBoundary = 0;
    let rightBoundary = groups.length - 1;

    // Определение левой границы
    for (let i = groupIndex - 1; i >= 0; i--) {
      if (targetScheduleDay[i] && targetScheduleDay[i][lessonIndex]) {
        leftBoundary = i + 1;
        break;
      }
    }

    // Определение правой границы
    for (let i = lastIndex + 1; i < groups.length; i++) {
      if (targetScheduleDay[i] && targetScheduleDay[i][lessonIndex]) {
        rightBoundary = i - 1;
        break;
      }
    }

    return { left: leftBoundary, right: rightBoundary };
  }

  const getMaxLessonsPerDay = (weekIndex, dayIndex) => {
    const cellKey = `${weekIndex}-${dayIndex}`;
    return schedule[cellKey] ? schedule[cellKey].lessons.max + 1 : 1;
  };

  return (
    <div className='extended-container'>
      <div className='table-outer-wrapper'>
        <div className='table-wrapper'>
          {debug && (<button onClick={() => console.log(schedule)} className='console-button'>Лог массива ячеек расписания</button>)}
          {debug && (<button onClick={() => setSchedule({})} className='console-button'>reset schedule</button>)}
          <table className='table'>
            <thead>
              <tr>
                <th>Неделя/День</th>
                {groups.map(group => (
                  <GroupActions
                    key={group.id}
                    group={group}
                    openEditModal={openEditModal}
                    onDeleteGroup={handleDeleteGroup}
                  />
                ))}
                <th>
                  {isAddModalOpen ? (
                    <Modal title="Добавить группу" onClose={closeAddModal}>
                      <input
                        type="text"
                        placeholder="Название группы"
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                      />
                      <button disabled={!newGroupName} onClick={handleAddGroup} className='group-add-button'><span>Добавить</span><IonIcon className='group-add-button-icon' icon={addOutline} /></button>
                    </Modal>
                  ) : (
                    <button className='group-add-button' onClick={openAddModal}><span>Добавить группу</span><IonIcon className='group-add-button-icon' icon={addOutline} /></button>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, weekIndex) => (
                <React.Fragment key={weekIndex}>
                  <WeekHeader week={week} weekIndex={weekIndex} weeks={weeks} onDeleteWeek={handleDeleteWeek} groups={groups} />
                  {daysOfWeek.map((day, dayIndex) => (
                    <DayRow
                      key={dayIndex}
                      day={day}
                      weekIndex={weekIndex}
                      dayIndex={dayIndex}
                      groups={groups}
                      schedule={schedule}
                      handleDropClass={handleDropClass}
                      handleRemoveClass={handleRemoveClass}
                      handleMoveClass={handleMoveClass}
                      resizing={resizing}
                      resizingRef={resizingRef}
                      cursorCellRef={cursorCellRef}
                      setResizing={setResizing}
                      mergeClass={mergeClass}
                      cursorCell={cursorCell}
                      setCursorCell={setCursorCell}
                      getLastIndex={getLastIndex}
                      getBoundaries={getBoundaries}
                      getMaxLessonsPerDay={getMaxLessonsPerDay}
                      teachers={teachers}
                      locations={locations}
                      onUpdateClass={handleUpdateClass}
                      semester={semester}
                      maxWeeks={weeks.length}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  ))}
                </React.Fragment>
              ))}
              <tr>
                <td colSpan={groups.length + 1}>
                  <button className='week-add-button' onClick={handleAddWeek}><span>Добавить учебную неделю</span><IonIcon className='week-add-button-icon' icon={addOutline} /></button>
                </td>
              </tr>
            </tbody>
          </table>

          {isEditModalOpen && (
            <Modal title="Редактировать группу" onClose={closeEditModal} style={{ top: editModalPosition.top, left: editModalPosition.left }}>
              <div className='group-edit-children'>
                <input
                  type="text"
                  placeholder="Название группы"
                  value={editGroupName}
                  onChange={(e) => setEditGroupName(e.target.value)}
                />
                <button onClick={handleEditGroup} className='save-button'><IonIcon icon={checkmarkOutline} /></button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleTable;
