import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../../../services/api';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import customStyles from './css/customSelectStyles';
import paginationStyles from './css/paginationSelectStyles';

const SpecialityEditor = () => {
  const [faculties, setFaculties] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState('');
  const [newSpeciality, setNewSpeciality] = useState({ program: 'Бакалавриат', identifier: '', name: '' });
  const [editSpecialityId, setEditSpecialityId] = useState(null);
  const [editSpeciality, setEditSpeciality] = useState({ program: '', identifier: '', name: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [loadingFaculties, setLoadingFaculties] = useState(true); // Состояние для загрузки факультетов
  const [loadingSpecialities, setLoadingSpecialities] = useState(false); // Состояние для загрузки специальностей
  const [searchFields] = useState(['name', 'identifier']);
  const [searchActive, setSearchActive] = useState(false);
  const searchRef = useRef(null);
  const buttonRef = useRef(null);

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];


  const fetchFaculties = useCallback(async () => {
    try {
      setLoadingFaculties(true);
      const response = await api.get('/faculty/all');
      setFaculties(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке факультетов', error);
    } finally {
      setLoadingFaculties(false);
    }
  }, []);

  useEffect(() => {
    fetchFaculties();
  }, [fetchFaculties]);

  const fetchSpecialities = useCallback(async () => {
    try {
      setLoadingSpecialities(true);
      let req = `/speciality/by-foreign-key/faculty_id/${selectedFacultyId}?page=${page}&limit=${limit}`;
      if (search && searchFields) {
        searchFields.forEach(field => {
          req += `&searchFields=${field}`;
        });
        req += `&search=${search}`;
      }
      if (sort) req += `&sort=${sort}`;

      const response = await api.get(req);
      const data = response.data;
      setSpecialities(data.data || []);
      setTotalPages(data.pagination ? data.pagination.totalPages : 1);
      if (page > (data.pagination ? data.pagination.totalPages : 1)) {
        setPage(1);
      }
    } catch (error) {
      console.error('Ошибка при загрузке специальностей', error);
      setSpecialities([]);
      setTotalPages(1);
    } finally {
      setLoadingSpecialities(false);
    }
  }, [selectedFacultyId, page, limit, search, searchFields, sort]);

  useEffect(() => {
    if (selectedFacultyId) {
      fetchSpecialities();
    }
  }, [selectedFacultyId, page, limit, search, fetchSpecialities]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const addSpeciality = async () => {
    try {
      await api.post('/speciality', { ...newSpeciality, faculty_id: selectedFacultyId });
      toast.success('Специальность добавлена!');
      fetchSpecialities();
      setNewSpeciality({ program: 'Бакалавриат', identifier: '', name: '' });
      setShowAddForm(false);
    } catch (error) {
      console.error('Ошибка при добавлении специальности', error);
      toast.error('Ошибка при добавлении специальности');
    }
  };

  const deleteSpeciality = async (id) => {
    try {
      await api.delete(`/speciality/${id}`);
      toast.success('Специальность удалена!');
      fetchSpecialities();
    } catch (error) {
      console.error('Ошибка при удалении специальности', error);
      toast.error('Ошибка при удалении специальности');
    }
  };

  const startEditSpeciality = (speciality) => {
    setEditSpecialityId(speciality.id);
    setEditSpeciality({ program: speciality.program, identifier: speciality.identifier, name: speciality.name });
  };

  const cancelEditSpeciality = () => {
    setEditSpecialityId(null);
    setEditSpeciality({ program: '', identifier: '', name: '' });
  };

  const updateSpeciality = async () => {
    try {
      await api.put(`/speciality/${editSpecialityId}`, { ...editSpeciality, faculty_id: selectedFacultyId });
      toast.success('Специальность обновлена!');
      fetchSpecialities();
      cancelEditSpeciality();
    } catch (error) {
      console.error('Ошибка при обновлении специальности', error);
      toast.error('Ошибка при обновлении специальности');
    }
  };

  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    searchElement.style.minWidth = '200px';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      searchElement.style.minWidth = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  const toggleAddForm = () => {
    setIsAnimating(true);

    if (!showAddForm) {
      setTimeout(() => {
        setShowAddForm(true);
        setIsAnimating(false);
      }, 150);
    } else {
      setIsClosing(true);
      setShowAddForm(false);
      setIsAnimating(false);
      setIsClosing(false);
    }
  };

  useEffect(() => {
    const updateModalPosition = () => {
      if (buttonRef.current && showAddForm) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const modalContent = document.querySelector('.editor-add-modal');

        if (modalContent) {
          modalContent.style.top = `${buttonRect.bottom}px`;
          modalContent.style.left = `${buttonRect.left}px`;
        }
      }
    };

    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    updateModalPosition();

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, [showAddForm]);

  useEffect(() => { if (!selectedFacultyId) (setSearch('')) }, [selectedFacultyId]);

  return (
    <div className='container'>
      <div className='selects-wrapper'>
        <label className='select-label'>
          <span className='select-title'>Факультет</span>
          {loadingFaculties ? (
            <Skeleton height={38} width={200} />
          ) : (
            <Select
              styles={customStyles}
              value={faculties.find(faculty => faculty.value === selectedFacultyId)}
              onChange={(selectedOption) => setSelectedFacultyId(selectedOption ? selectedOption.value : '')}
              options={faculties.map(faculty => ({ value: faculty.id, label: faculty.name }))}
              placeholder="Выберите факультет"
              noOptionsMessage={() => "Факультеты не найдены"}
              isClearable
            />
          )}
        </label>
      </div>
      {selectedFacultyId && (
        <>
          <div className='actions-list'>
            <button
              ref={buttonRef}
              onClick={toggleAddForm}
              className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
            >
              <span className="add-entity-button-text">
                <span className="text-add">Добавить специальность</span>
                <span className="text-cancel">Отмена</span>
              </span>
              <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
            </button>
            <Modal
              isOpen={showAddForm}
              onRequestClose={toggleAddForm}
              contentLabel="Добавить специальность"
              className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
              overlayClassName="editor-add-overlay"
              closeTimeoutMS={300}
              style={{
                content: {
                  top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
                  left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
                  transform: 'translate(0, 0)',
                },
              }}
            >
              <h3>Добавить специальность</h3>
              <div className='editor-add-modal-form'>
                <div className='editor-add-modal-form-input-wrapper'>
                  <input
                    type="text"
                    placeholder="Название специальности"
                    value={newSpeciality.name}
                    onChange={(e) => setNewSpeciality({ ...newSpeciality, name: e.target.value })}
                  />
                  {newSpeciality.name && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewSpeciality({ ...newSpeciality, name: '' })}><IonIcon icon={closeOutline} /></button>}
                </div>
                <div className='editor-add-modal-form-input-wrapper'>
                  <input
                    type="text"
                    placeholder="Идентификатор"
                    value={newSpeciality.identifier}
                    onChange={(e) => setNewSpeciality({ ...newSpeciality, identifier: e.target.value })}
                  />
                  {newSpeciality.identifier && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewSpeciality({ ...newSpeciality, identifier: '' })}><IonIcon icon={closeOutline} /></button>}
                </div>
                <Select
                  styles={customStyles}
                  value={{ value: newSpeciality.program, label: newSpeciality.program }}
                  onChange={(selectedOption) => setNewSpeciality({ ...newSpeciality, program: selectedOption.value })}
                  options={[
                    { value: 'Бакалавриат', label: 'Бакалавриат' },
                    { value: 'Специалитет', label: 'Специалитет' },
                    { value: 'Магистратура', label: 'Магистратура' },
                    { value: 'Ординатура', label: 'Ординатура' },
                  ]}
                  placeholder="Выберите программу"
                />
                <button disabled={!newSpeciality.name || !newSpeciality.identifier} onClick={addSpeciality} className='editor-add-modal-form-add-button'><span className='editor-add-modal-form-add-button-text'>Добавить</span><IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} /></button>
              </div>
            </Modal>
            <div className='arrange-list'>
              <button
                className='search-bar-button'
                onClick={toggleSearch}
                onMouseDown={(e) => e.preventDefault()}
              >
                <IonIcon className='search-bar-icon' icon={searchOutline} />
              </button>
              <input
                type="text"
                placeholder="Поиск"
                className='search-bar'
                value={search}
                ref={searchRef}
                onChange={handleSearchChange}
                onBlur={handleSearchBlur}
              />
              {searchActive && search && (
                <button onClick={() => { setSearch(''); searchRef.current.focus() }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
              )}
              <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('name ASC'): return 'name DESC'; case ('name DESC'): return ''; default: return 'name ASC' } })}>А-Я
                {sort === 'name ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
                {sort === 'name DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
                {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
              </button>
              <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('identifier ASC'): return 'identifier DESC'; case ('identifier DESC'): return ''; default: return 'identifier ASC' } })}>ID
                {sort === 'identifier ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
                {sort === 'identifier DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
                {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
              </button>
              <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('program ASC'): return 'program DESC'; case ('program DESC'): return ''; default: return 'program ASC' } })}>Программа
                {sort === 'program ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
                {sort === 'program DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
                {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
              </button>
              <button className='fetch-entities' onClick={fetchSpecialities}><IonIcon icon={checkmarkOutline} /></button>
            </div>
          </div>
          <div className='content-area'>
            <ul className='entity-list'>
              <li className='entity-header speciality'>
                <span>Название</span>
                <span>Идентификатор (<b>ID</b>)</span>
                <span >Программа</span>
                <span className='entity-header-actions'>Действия</span>
              </li>
              {loadingSpecialities ? (
                Array(10).fill().map((_, index) => (
                  <li key={index} className="entity-item speciality">
                    <span className='entity-item-name'><Skeleton width={200} /></span>
                    <span className='speciality-item-id'><Skeleton width={100} /></span>
                    <span><Skeleton width={120} /></span>
                    <Skeleton width={30} height={30} />
                    <Skeleton width={30} height={30} />
                  </li>
                ))
              ) : (
                specialities.map(speciality => (
                  <li className={`entity-item speciality ${speciality.id === editSpecialityId ? 'editing' : ''}`} key={speciality.id}>
                    {speciality.id === editSpecialityId ? (
                      <>
                        <input
                          type="text"
                          value={editSpeciality.name}
                          onChange={(e) => setEditSpeciality({ ...editSpeciality, name: e.target.value })}
                        />
                        <input
                          type="text"
                          value={editSpeciality.identifier}
                          onChange={(e) => setEditSpeciality({ ...editSpeciality, identifier: e.target.value })}
                        />
                        <Select
                          styles={customStyles}
                          value={{ value: editSpeciality.program, label: editSpeciality.program }}
                          onChange={(selectedOption) => setEditSpeciality({ ...editSpeciality, program: selectedOption.value })}
                          options={[
                            { value: 'Бакалавриат', label: 'Бакалавриат' },
                            { value: 'Специалитет', label: 'Специалитет' },
                            { value: 'Магистратура', label: 'Магистратура' },
                            { value: 'Ординатура', label: 'Ординатура' },
                          ]}
                          placeholder="Программа"
                        />
                        <button className='entity-item-button done' onClick={updateSpeciality}><IonIcon icon={checkmarkOutline} /></button>
                        <button className='entity-item-button cancel' onClick={cancelEditSpeciality}><IonIcon icon={closeOutline} /></button>
                      </>
                    ) : (
                      <>
                        <span className='entity-item-name'>{speciality.name}</span>
                        <span className='speciality-item-id'>{speciality.identifier}</span>
                        <span >{speciality.program}</span>

                        <button className='entity-item-button edit' onClick={() => startEditSpeciality(speciality)}><IonIcon icon={createOutline} /></button>
                        <button className='entity-item-button delete' onClick={() => deleteSpeciality(speciality.id)}><IonIcon icon={trashOutline} /></button>
                      </>
                    )}
                  </li>
                ))
              )}
            </ul>
            <div className='page-navigation'>
              <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
              <span>Страница {page} из {totalPages}</span>
              <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
            </div>
            <div className='page-pagination'>
              <label>Количество строк на странице</label>
              <Select
                styles={paginationStyles}
                value={paginationOptions.find(option => option.value === limit)}
                onChange={(selectedOption) => { setLimit(selectedOption.value) }}
                options={paginationOptions}
                isSearchable={false}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SpecialityEditor;
