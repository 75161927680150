// customSelectStyles.js
const paginationStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '2rem',
    borderColor: '#ced4da',
    minHeight: '38px',
    height: '38px',
    boxShadow: '0 0 1px 0 rgba(109, 93, 252, 0.15), 0 6px 12px 0 rgba(109, 93, 252, 0.15)',
    ':hover': {
      borderColor: 'var(--primary-color)',
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '38px',
    padding: '0px 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '38px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
    cursor: 'pointer',
    ':hover': {
      color: '#e03f34',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingLeft: '2px',
    cursor: 'pointer',
    ':hover': {
      color: '#6d5dfc',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    marginTop: '2px',
    padding: "0 0 0 4px ",
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    paddingRight: '4px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#6d5dfc',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#5b0eeb',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '6px 10px',
    borderRadius: '2rem',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? state.isFocused ? '#5b0eeb' : '#6d5dfc' : state.isFocused ? 'var(--secondary-color)' : 'white',
    color: state.isSelected ? 'white' : state.isFocused ? 'var(--primary-color)' : 'black',
    transition: 'background-color 0.3s ease',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '4px 0',
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

export default paginationStyles;
