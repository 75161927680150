import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import NotFound from './pages/NotFound';
import Forbidden from './pages/Forbidden';
import FacultyPage from './pages/FacultyPage';
import SpecialityPage from './pages/SpecialityPage';
import CoursePage from './pages/CoursePage';
import GroupPage from './pages/GroupPage';
import Profile from './pages/Profile';
import AuthModal from './components/Auth/AuthModal';
import ScheduleEditor from './pages/ScheduleEditor';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoaderProvider } from './context/LoaderContext';
import Loader from './components/Loader/Loader';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <LoaderProvider>
      <Loader />
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <div className='body-container'>
              <Navbar />
              <Routes>
                <Route path="/" element={<FacultyPage />} />
                <Route path="/faculty/:facultyId/specialities" element={<SpecialityPage />} />
                <Route path="/faculty/:facultyId/speciality/:specialityId/courses" element={<CoursePage />} />
                <Route path="/faculty/:facultyId/speciality/:specialityId/course/:courseId/groups" element={<GroupPage />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/editor" element={<ScheduleEditor />} />
                <Route path='/403' element={<Forbidden />} />
                <Route path='/profile' element={<Profile />} />
              </Routes>
              <AuthContext.Consumer>
                {({ isAuthModalOpen, closeAuthModal, handleLogin }) => (
                  <AuthModal isOpen={isAuthModalOpen} onRequestClose={closeAuthModal} onLogin={handleLogin} />
                )}
              </AuthContext.Consumer>
              <ToastContainer position="bottom-right" limit={5} />
            </div>
          </Router>
        </DndProvider>
      </AuthProvider>
    </LoaderProvider>
  );
}

export default React.memo(App);
