import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Select from 'react-select';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import DateObject from "react-date-object";
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import customStyles from './css/customSelectStyles';
import paginationStyles from './css/paginationSelectStyles';


const ClassTemplateEditor = () => {

  const [templates, setTemplates] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [newTemplate, setNewTemplate] = useState({ name: '', duration: new DateObject({ hour: 1, minute: 30 }), description: '', type: '', department_id: '' });
  const [editTemplateId, setEditTemplateId] = useState(null);
  const [editTemplate, setEditTemplate] = useState({ name: '', duration: '', description: '', type: '', department_id: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [filterType, setFilterType] = useState('');
  const [sortDuration, setSortDuration] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const searchRef = useRef(null);
  const buttonRef = useRef(null);

  const types = [
    { value: 'Лекция', label: 'Лекция' },
    { value: 'Лабораторная работа', label: 'Лабораторная работа' },
    { value: 'Практическое занятие', label: 'Практическое занятие' },
    { value: 'Консультация', label: 'Консультация' },
    { value: 'Курация', label: 'Курация' },
    { value: 'Зачёт', label: 'Зачёт' },
    { value: 'Экзамен', label: 'Экзамен' },
    { value: 'Дифференциальный зачёт', label: 'Дифференциальный зачёт' },
    { value: 'Коллоквиум', label: 'Коллоквиум' }
  ];

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const customStylesFilter = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      minWidth: '250px',
      borderRadius: '2rem',
      borderColor: '#ced4da',
      minHeight: '38px',
      height: '38px',
      boxShadow: '0 0 1px 0 rgba(109, 93, 252, 0.15), 0 6px 12px 0 rgba(109, 93, 252, 0.15)',
      ':hover': {
        borderColor: 'var(--primary-color)',
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0px 6px',
      fontWeight: '600',
      fontSize: '20px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '38px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      padding: '8px 2px',
      cursor: 'pointer',
      ':hover': {
        color: '#e03f34',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: '2px',
      cursor: 'pointer',
      ':hover': {
        color: '#6d5dfc',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      borderBottomLeftRadius: '1rem',
      borderBottomRightRadius: '1rem',
      marginTop: '2px',
      padding: "0 0 0 4px ",
      overflow: 'hidden',
    }),
    menuList: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      paddingRight: '4px',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#6d5dfc',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#5b0eeb',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '6px 10px',
      borderRadius: '2rem',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? state.isFocused ? '#5b0eeb' : '#6d5dfc' : state.isFocused ? 'var(--secondary-color)' : 'white',
      color: state.isSelected ? 'white' : state.isFocused ? 'var(--primary-color)' : 'black',
      transition: 'background-color 0.3s ease',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '4px 0',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };

  const fetchDepartments = useCallback(async () => {
    try {
      let req = `/department/all`;
      const response = await api.get(req);
      const data = response.data;
      setDepartments(data || []);
    } catch (error) {
      console.error('Ошибка при загрузке кафедр', error);
      setDepartments([]);
    }
  }, []);

  useEffect(() => { fetchDepartments() }, [fetchDepartments]);

  const fetchTemplates = useCallback(async () => {
    try {
      setLoadingTemplates(true);
      let req = `/class_template?page=${page}&limit=${limit}`;
      if (search) {
        req += `&searchFields=name&searchFields=type&search=${search}`;
      }
      if (filterType) {
        req += `&type=${filterType}`;
      }
      if (sort) req += `&sort=${sort}`;
      if (sortDuration) req += `&sort=${sortDuration}`;

      const response = await api.get(req);
      const data = response.data;
      setTemplates(data.data || []);
      setTotalPages(data.pagination ? data.pagination.totalPages : 1);
      if (page > (data.pagination ? data.pagination.totalPages : 1)) {
        setPage(1);
      }
    } catch (error) {
      toast.error('Ошибка при загрузке шаблонов занятий');
      setTemplates([]);
      setTotalPages(1);
    } finally {
      setLoadingTemplates(false);
    }
  }, [page, limit, search, sort, filterType, sortDuration]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const convertStringToDateObject = (timeString) => {
    if (timeString instanceof DateObject) {
      return timeString;
    }
    if (typeof timeString !== 'string') {
      return new DateObject(); // Return a default DateObject or handle this case as appropriate
    }
    const [hours, minutes] = timeString.split(":").map(Number);
    return new DateObject({ hour: hours, minute: minutes });
  };

  const convertDateObjectToString = (dateObject) => {
    return dateObject.format("HH:mm");
  };

  const handleAddTemplate = async () => {
    const templateToAdd = {
      ...newTemplate,
      duration: convertDateObjectToString(newTemplate.duration)
    };
    try {
      await api.post('/class_template', templateToAdd);
      toast.success('Шаблон занятий успешно добавлен');
      fetchTemplates();
      setNewTemplate({
        name: '',
        duration: new DateObject({ hour: 1, minute: 30 }), // Установить дефолтное значение 1 час 30 минут
        description: '',
        type: '',
        department_id: ''
      });
      setShowAddForm(false);
    } catch (error) {
      toast.error('Ошибка при добавлении шаблона занятий');
    }
  };

  const handleUpdateTemplate = async () => {
    const templateToUpdate = {
      ...editTemplate,
      duration: convertDateObjectToString(editTemplate.duration)
    };
    try {
      await api.put(`/class_template/${editTemplateId}`, templateToUpdate);
      toast.success('Шаблон занятий успешно обновлен');
      fetchTemplates();
      setEditTemplateId(null);
      setEditTemplate({ name: '', duration: '', description: '', type: '' });
    } catch (error) {
      toast.error('Ошибка при обновлении шаблона занятий');
    }
  };

  const handleDeleteTemplate = async (id) => {
    try {
      await api.delete(`/class_template/${id}`);
      toast.success('Шаблон занятий успешно удален');
      fetchTemplates();
    } catch (error) {
      toast.error('Ошибка при удалении шаблона занятий');
    }
  };

  const startEditTemplate = (template) => {
    setEditTemplateId(template.id);
    setEditTemplate({
      ...template,
      duration: template.duration instanceof DateObject
        ? template.duration
        : convertStringToDateObject(template.duration)
    });
  };

  const cancelEditTemplate = () => {
    setEditTemplateId(null);
    setEditTemplate({ name: '', duration: '', description: '', type: '' });
  };


  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);


  const toggleAddForm = () => {
    if (isAnimating) {
      return;
    } else {
      setIsAnimating(true);
      if (!showAddForm) {
        setTimeout(() => {
          setShowAddForm(true);
          setIsAnimating(false);
        }, 150);
      } else {
        setIsClosing(true);
        setShowAddForm(false);
        setIsAnimating(false);
        setIsClosing(false);
      }
    }
  };

  useEffect(() => {
    const updateModalPosition = () => {
      if (buttonRef.current && showAddForm) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const modalContent = document.querySelector('.editor-add-modal');

        if (modalContent) {
          modalContent.style.top = `${buttonRect.bottom}px`;
          modalContent.style.left = `${buttonRect.left}px`;
        }
      }
    };

    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    updateModalPosition();

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, [showAddForm]);

  useEffect(() => {
    if (showAddForm) {
      const handleResize = () => {
        setTimeout(() => {
          const DOMModal = document.getElementById('modal-content-add-window');
          if (DOMModal) {
            const windowHeight = window.innerHeight;
            const modalBoundingRect = DOMModal.getBoundingClientRect();
            const modalHeight = modalBoundingRect.top + modalBoundingRect.height;
            if (modalHeight > windowHeight) {
              document.body.style.height = `${modalHeight + 250}px`;
            }
          }
        }, 0);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    } else {
      document.body.style.height = '';
    }
  }, [showAddForm]);

  const selectDepartments = useCallback(() => {
    return departments.map((dept) => ({ value: dept.id, label: dept.name }));
  }, [departments]);

  return (
    <div className='extended-container'>
      <div className='actions-list'>
        <button
          ref={buttonRef}
          onClick={toggleAddForm}
          className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
        >
          <span className="add-entity-button-text">
            <span className="text-add">Добавить шаблон занятия</span>
            <span className="text-cancel">Отмена</span>
          </span>
          <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
        </button>
        <Modal
          isOpen={showAddForm}
          onRequestClose={toggleAddForm}
          id='modal-content-add-window'
          contentLabel="Добавить шаблон занятия"
          className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
          overlayClassName="editor-add-overlay"
          closeTimeoutMS={300}
          style={{
            content: {
              top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
              left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
              transform: 'translate(0, 0)'
            },
          }}
        >
          <h3>Добавить шаблон занятия</h3>
          <div className='editor-add-modal-form'>
            <div className='editor-add-modal-form-input-wrapper'>
              <span className='editor-add-modal-form-input-wrapper-hint'>Название</span>
              <input
                type="text"
                placeholder="Название"
                value={newTemplate.name}
                onChange={(e) => setNewTemplate({ ...newTemplate, name: e.target.value })}
              />
              {newTemplate.name && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewTemplate({ ...newTemplate, name: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <span className='editor-add-modal-form-input-wrapper-hint'>Введите длительность</span>
              <DatePicker
                value={newTemplate.duration ? convertStringToDateObject(newTemplate.duration) : ""}
                onChange={(date) => setNewTemplate({ ...newTemplate, duration: date })}
                format='HH:mm'
                disableDayPicker
                plugins={[<TimePicker hideSeconds />]}
                placeholder=""
                clearIndicator={true}
              />
              {newTemplate.duration && <button className='editor-add-modal-form-input-wrapper-remove-duration' onClick={() => setNewTemplate({ ...newTemplate, duration: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <span className='editor-add-modal-form-input-wrapper-hint'>Дополнительное описание</span>
              <textarea
                placeholder="Описание"
                value={newTemplate.description}
                onChange={(e) => setNewTemplate({ ...newTemplate, description: e.target.value })}
              />
              {newTemplate.description && <button className='editor-add-modal-form-input-wrapper-remove-description' onClick={() => setNewTemplate({ ...newTemplate, description: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <span className='editor-add-modal-form-input-wrapper-hint'>Тип занятия</span>
              <Select
                styles={customStyles}
                value={types.find(type => type.value === newTemplate.type)}
                onChange={(selectedOption) => setNewTemplate(selectedOption ? { ...newTemplate, type: selectedOption.value } : { ...newTemplate, type: '' })}
                options={types}
                noOptionsMessage={() => ("Типы занятий не найдены")}
                placeholder="Выберите тип"
                isClearable
              />
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <span className='editor-add-modal-form-input-wrapper-hint'>Кафедра</span>
              <Select
                styles={customStyles}
                value={departments.find(dept => dept.value === newTemplate.department_id)}
                onChange={(selectedOption) => setNewTemplate(selectedOption ? { ...newTemplate, department_id: selectedOption.value } : { ...newTemplate, department_id: '' })}
                options={selectDepartments()}
                placeholder="Выберите кафедру"
                noOptionsMessage={() => ("Кафедры не найдены")}
                isClearable
              />
            </div>
            <button
              disabled={!newTemplate.name || !newTemplate.duration || !newTemplate.type}
              onClick={handleAddTemplate} className='editor-add-modal-form-add-button'>
              <span className='editor-add-modal-form-add-button-text'>Добавить</span>
              <IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} />
            </button>
          </div>
        </Modal>
        <div className='arrange-list'>
          <button
            className='search-bar-button'
            onClick={toggleSearch}
            onMouseDown={(e) => e.preventDefault()}
          >
            <IonIcon className='search-bar-icon' icon={searchOutline} />
          </button>
          <input
            type="text"
            placeholder="Поиск"
            className='search-bar'
            value={search}
            ref={searchRef}
            onChange={handleSearchChange}
            onBlur={handleSearchBlur}
          />
          {searchActive && search && (
            <button onClick={() => { setSearch(''); searchRef.current.focus() }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
          )}
          <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('name ASC'): return 'name DESC'; case ('name DESC'): return ''; default: return 'name ASC' } })}>Название
            {sort === 'name ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
            {sort === 'name DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
            {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
          </button>
          <button className={`filter-button ${sortDuration !== '' ? 'active' : ''}`} onClick={() => setSortDuration((sortDuration) => { switch (sortDuration) { case ('duration ASC'): return 'duration DESC'; case ('duration DESC'): return ''; default: return 'duration ASC' } })}>
            Длительность
            {sortDuration === 'duration ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
            {sortDuration === 'duration DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
            {sortDuration === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
          </button>
          <Select
            styles={customStylesFilter}
            value={types.find(type => type.value === filterType)}
            onChange={(selectedOption) => setFilterType(selectedOption ? selectedOption.value : '')}
            options={types}
            placeholder="Фильтр по типу"
            noOptionsMessage={() => ("Типов занятий не найдено")}
            isClearable
          />
          <button className='fetch-entities' onClick={fetchTemplates}><IonIcon icon={checkmarkOutline} /></button>
        </div>
      </div>
      <div className='content-area'>
        <ul className='entity-list'>
          <li className='entity-header template'>
            <span>Название</span>
            <span>Кафедра</span>
            <span>Длительность</span>
            <span>Описание</span>
            <span>Тип</span>
            <span className='entity-header-actions'>Действия</span>
          </li>
          {loadingTemplates ? (
            Array(10).fill().map((_, index) => (
              <li key={index} className="entity-item template">
                <span className='entity-item-name'><Skeleton width={150} /></span>
                <span><Skeleton width={80} /></span>
                <span><Skeleton width={120} /></span>
                <span><Skeleton width={200} /></span>
                <span><Skeleton width={120} /></span>
                <Skeleton width={30} height={30} />
                <Skeleton width={30} height={30} />
              </li>
            ))
          ) : (
            templates.map(template => (
              <li className={`entity-item template ${template.id === editTemplateId ? 'editing' : ''}`} key={template.id}>
                {template.id === editTemplateId ? (
                  <>
                    <input
                      type="text"
                      value={editTemplate.name}
                      onChange={(e) => setEditTemplate({ ...editTemplate, name: e.target.value })}
                    />
                    <Select
                      styles={customStyles}
                      value={
                        departments.find(dept => dept.id === editTemplate.department_id)
                          ? { value: editTemplate.department_id, label: departments.find(dept => dept.id === editTemplate.department_id).name }
                          : null}
                      onChange={(selectedOption) => setEditTemplate(selectedOption ? { ...editTemplate, department_id: selectedOption.value } : { ...editTemplate, department_id: '' })}
                      options={selectDepartments()}
                      placeholder="Выберите кафедру"
                      noOptionsMessage={() => ("Кафедры не найдены")}
                      isClearable
                    />
                    <DatePicker
                      value={editTemplate.duration ? convertStringToDateObject(editTemplate.duration) : ""}
                      onChange={(date) => setEditTemplate({ ...editTemplate, duration: date })}
                      format='HH:mm'
                      disableDayPicker
                      plugins={[<TimePicker hideSeconds />]}
                      placeholder="Введите длительность"
                    />
                    <textarea
                      value={editTemplate.description}
                      onChange={(e) => setEditTemplate({ ...editTemplate, description: e.target.value })}
                    />
                    <Select
                      styles={customStyles}
                      value={types.find(type => type.value === editTemplate.type) || null}
                      onChange={(selectedOption) => setEditTemplate(selectedOption ? { ...editTemplate, type: selectedOption.value } : { name: '', duration: '', description: '', type: '' })}
                      options={types}
                      placeholder="Выберите тип"
                      isClearable
                    />
                    <button className='entity-item-button done' onClick={handleUpdateTemplate}><IonIcon icon={checkmarkOutline} /></button>
                    <button className='entity-item-button cancel' onClick={cancelEditTemplate}><IonIcon icon={closeOutline} /></button>
                  </>
                ) : (
                  <>
                    <span className='entity-item-name'>{template.name}</span>
                    <span>
                      {departments &&
                        departments.find(dept => dept.id === template.department_id)
                        ? departments.find(dept => dept.id === template.department_id).name
                        : 'Неизвестная кафедра'}
                    </span>
                    <span>{template.duration && (template.duration.slice(0, 2) + 'ч ' + template.duration.slice(3, 5) + 'мин')}</span>
                    <span>{template.description}</span>
                    <span>{template.type}</span>
                    <button className='entity-item-button edit' onClick={() => startEditTemplate(template)}><IonIcon icon={createOutline} /></button>
                    <button className='entity-item-button delete' onClick={() => handleDeleteTemplate(template.id)}><IonIcon icon={trashOutline} /></button>
                  </>
                )}
              </li>
            ))
          )}
        </ul>
        <div className='page-navigation'>
          <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
          <span>Страница {page} из {totalPages}</span>
          <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
        </div>
        <div className='page-pagination'>
          <label>Количество строк на странице</label>
          <Select
            styles={paginationStyles}
            value={paginationOptions.find(option => option.value === limit)}
            onChange={(selectedOption) => { setLimit(selectedOption.value) }}
            options={paginationOptions}
            isSearchable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassTemplateEditor;
