import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Select from 'react-select';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import paginationStyles from './css/paginationSelectStyles';

const AuditoriumEditor = () => {
  const [auditoriums, setAuditoriums] = useState([]);
  const [newAuditorium, setNewAuditorium] = useState({ address: '', name: '', floor: '' });
  const [editAuditoriumId, setEditAuditoriumId] = useState(null);
  const [editAuditorium, setEditAuditorium] = useState({ address: '', name: '', floor: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [loadingAuditoriums, setLoadingAuditoriums] = useState(true); // Состояние загрузки аудиторий
  const [searchActive, setSearchActive] = useState(false);
  const searchRef = useRef(null);
  const buttonRef = useRef(null);

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const fetchAuditoriums = useCallback(async () => {
    try {
      setLoadingAuditoriums(true); // Начало загрузки
      let req = `/auditorium?page=${page}&limit=${limit}`;
      if (search) {
        req += `&searchFields=name&searchFields=address&search=${search}`;
      }
      if (sort) req += `&sort=${sort}`;

      const response = await api.get(req);
      const data = response.data;
      setAuditoriums(data.data || []);
      setTotalPages(data.pagination ? data.pagination.totalPages : 1);
      if (page > (data.pagination ? data.pagination.totalPages : 1)) {
        setPage(1);
      }
    } catch (error) {
      toast.error('Ошибка при загрузке аудиторий');
      setAuditoriums([]);
      setTotalPages(1);
    } finally {
      setLoadingAuditoriums(false); // Окончание загрузки
    }
  }, [page, limit, search, sort]);

  useEffect(() => {
    fetchAuditoriums();
  }, [fetchAuditoriums]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const addAuditorium = async () => {
    try {
      await api.post('/auditorium', newAuditorium);
      toast.success('Аудитория добавлена!');
      fetchAuditoriums();
      setNewAuditorium({ address: '', name: '', floor: '' });
      setShowAddForm(false);
    } catch (error) {
      toast.error('Ошибка при добавлении аудитории');
    }
  };

  const updateAuditorium = async () => {
    try {
      await api.put(`/auditorium/${editAuditoriumId}`, editAuditorium);
      toast.success('Аудитория обновлена!');
      fetchAuditoriums();
      setEditAuditoriumId(null);
      setEditAuditorium({ address: '', name: '', floor: '' });
    } catch (error) {
      toast.error('Ошибка при обновлении аудитории');
    }
  };

  const deleteAuditorium = async (id) => {
    try {
      await api.delete(`/auditorium/${id}`);
      toast.success('Аудитория удалена!');
      fetchAuditoriums();
    } catch (error) {
      toast.error('Ошибка при удалении аудитории');
    }
  };

  const startEditAuditorium = (auditorium) => {
    setEditAuditoriumId(auditorium.id);
    setEditAuditorium({ address: auditorium.address, name: auditorium.name, floor: auditorium.floor });
  };

  const cancelEditAuditorium = () => {
    setEditAuditoriumId(null);
    setEditAuditorium({ address: '', name: '', floor: '' });
  };

  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    searchElement.style.minWidth = '200px';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      searchElement.style.minWidth = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  const toggleAddForm = () => {
    setIsAnimating(true);

    if (!showAddForm) {
      setTimeout(() => {
        setShowAddForm(true);
        setIsAnimating(false);
      }, 150);
    } else {
      setIsClosing(true);
      setShowAddForm(false);
      setIsAnimating(false);
      setIsClosing(false);
    }
  };

  return (
    <div className='container'>
      <div className='actions-list'>
        <button
          ref={buttonRef}
          onClick={toggleAddForm}
          className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
        >
          <span className="add-entity-button-text">
            <span className="text-add">Добавить аудиторию</span>
            <span className="text-cancel">Отмена</span>
          </span>
          <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
        </button>
        <Modal
          isOpen={showAddForm}
          onRequestClose={toggleAddForm}
          contentLabel="Добавить аудиторию"
          className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
          overlayClassName="editor-add-overlay"
          closeTimeoutMS={300}
          style={{
            content: {
              top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
              left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
              transform: 'translate(0, 0)',
            },
          }}
        >
          <h3>Добавить аудиторию</h3>
          <div className='editor-add-modal-form'>
            <div className='editor-add-modal-form-input-wrapper'>
              <input
                type="text"
                placeholder="Название"
                value={newAuditorium.name}
                onChange={(e) => setNewAuditorium({ ...newAuditorium, name: e.target.value })}
              />
              {newAuditorium.name && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewAuditorium({ ...newAuditorium, name: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <input
                type="text"
                placeholder="Адрес"
                value={newAuditorium.address}
                onChange={(e) => setNewAuditorium({ ...newAuditorium, address: e.target.value })}
              />
              {newAuditorium.address && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewAuditorium({ ...newAuditorium, address: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <div className='editor-add-modal-form-input-wrapper'>
              <input
                type="number"
                placeholder="Этаж"
                min={-20}
                max={20}
                value={newAuditorium.floor}
                onChange={(e) => setNewAuditorium({ ...newAuditorium, floor: e.target.value })}
              />
              {newAuditorium.floor && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => setNewAuditorium({ ...newAuditorium, floor: '' })}><IonIcon icon={closeOutline} /></button>}
            </div>
            <button disabled={!newAuditorium.name || !newAuditorium.address || !newAuditorium.floor} onClick={addAuditorium} className='editor-add-modal-form-add-button'><span className='editor-add-modal-form-add-button-text'>Добавить</span><IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} /></button>
          </div>
        </Modal>
        <div className='arrange-list'>
          <button
            className='search-bar-button'
            onClick={toggleSearch}
            onMouseDown={(e) => e.preventDefault()}
          >
            <IonIcon className='search-bar-icon' icon={searchOutline} />
          </button>
          <input
            type="text"
            placeholder="Поиск"
            className='search-bar'
            value={search}
            ref={searchRef}
            onChange={handleSearchChange}
            onBlur={handleSearchBlur}
          />
          {searchActive && search && (
            <button onClick={() => { setSearch(''); searchRef.current.focus() }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
          )}
          <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={() => setSort((sort) => { switch (sort) { case ('name ASC'): return 'name DESC'; case ('name DESC'): return ''; default: return 'name ASC' } })}>Название
            {sort === 'name ASC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
            {sort === 'name DESC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
            {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
          </button>
          <button className='fetch-entities' onClick={fetchAuditoriums}><IonIcon icon={checkmarkOutline} /></button>
        </div>
      </div>
      <div className='content-area'>
        <ul className='entity-list'>
          <li className='entity-header auditorium'>
            <span>Название</span>
            <span>Адрес</span>
            <span>Этаж</span>
            <span className='entity-header-actions'>Действия</span>
          </li>
          {loadingAuditoriums ? (
            Array(10).fill().map((_, index) => (
              <li key={index} className="entity-item auditorium">
                <span className='entity-item-name'><Skeleton width={150} /></span>
                <span><Skeleton width={150} /></span>
                <span><Skeleton width={50} /></span>
                <Skeleton width={30} height={30} />
                <Skeleton width={30} height={30} />

              </li>
            ))
          ) : (
            auditoriums.map(auditorium => (
              <li className={`entity-item auditorium ${auditorium.id === editAuditoriumId ? 'editing' : ''}`} key={auditorium.id}>
                {auditorium.id === editAuditoriumId ? (
                  <>
                    <input
                      type="text"
                      value={editAuditorium.name}
                      onChange={(e) => setEditAuditorium({ ...editAuditorium, name: e.target.value })}
                    />
                    <input
                      type="text"
                      value={editAuditorium.address}
                      onChange={(e) => setEditAuditorium({ ...editAuditorium, address: e.target.value })}
                    />
                    <input
                      type="number"
                      value={editAuditorium.floor}
                      onChange={(e) => setEditAuditorium({ ...editAuditorium, floor: e.target.value })}
                    />
                    <button className='entity-item-button done' onClick={updateAuditorium}><IonIcon icon={checkmarkOutline} /></button>
                    <button className='entity-item-button cancel' onClick={cancelEditAuditorium}><IonIcon icon={closeOutline} /></button>
                  </>
                ) : (
                  <>
                    <span className='entity-item-name'>{auditorium.name}</span>
                    <span>{auditorium.address}</span>
                    <span>{auditorium.floor}</span>
                    <button className='entity-item-button edit' onClick={() => startEditAuditorium(auditorium)}><IonIcon icon={createOutline} /></button>
                    <button className='entity-item-button delete' onClick={() => deleteAuditorium(auditorium.id)}><IonIcon icon={trashOutline} /></button>
                  </>
                )}
              </li>
            ))
          )}
        </ul>
        <div className='page-navigation'>
          <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
          <span>Страница {page} из {totalPages}</span>
          <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
        </div>
        <div className='page-pagination'>
          <label>Количество строк на странице</label>
          <Select
            styles={paginationStyles}
            value={paginationOptions.find(option => option.value === limit)}
            onChange={(selectedOption) => { setLimit(selectedOption.value) }}
            options={paginationOptions}
            isSearchable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditoriumEditor;
