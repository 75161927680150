import { useState } from "react";
import api from '../../services/api';
import { toast } from "react-toastify";

const Login = ({ onLoginSuccess }) => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [isBlocked, setIsBlocked] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({ user: '', password: '' });

  const notify = (message, type = null, params = null) => type ? toast[type](message, params) : toast(message, params);

  const [awaitingLogin, setAwaitingLogin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAwaitingLogin(true);

    // Добавляем задержку перед отправкой запроса
    try {
      const response = await api.post('/u/login', formData);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      setLoginAttempts(0);
      onLoginSuccess(response.data.token, response.data.id, response.data.firstname, response.data.middlename, response.data.lastname, response.data.email, response.data.emailConfirmed);
      notify("Вход выполнен успешно!", "success");

    } catch (err) {
      if (err.response && err.response.status === 429) {
        setIsBlocked(true);
        setError('Превышено количество попыток входа. Попробуйте войти позже.');
        setTimeout(() => setIsBlocked(false), 15 * 60 * 1000); // блокировка на 15 минут
      } else if (err.response && err.response.data && err.response.data.message) {
        switch (err.response.data.message) {
          case ("Неверный пароль"):
            setErrors({ user: '', password: "Неверный пароль" });
            break;
          case ("Пользователь не найден."):
            setErrors({ user: "Пользователь не найден", password: '' });
            break;
          default:

        }
      } else {
        console.log(err);
        setError('Возникла ошибка при входе!');
        setLoginAttempts(() => {
          const newAttempts = loginAttempts + 1;
          if (newAttempts >= 5) {
            setIsBlocked(true);
            setTimeout(() => setIsBlocked(false), 15 * 60 * 1000);
          }
          return newAttempts;
        });
      }
    } finally {
      setAwaitingLogin(false);
    }
  };

  const handleInputChange = (target) => {
    const value = target.value.toString();
    const name = target.name;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors({ user: '', password: '' });
  }

  return (
    <>
      {isBlocked ? (
        <div>
          <p className="login-too-many">Слишком много попыток входа. Попробуйте позже.</p>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <label className="auth-label">
              <span className="field-name">Логин</span>
              <input
                type="text"
                value={formData.username}
                name="username"
                onChange={(e) => handleInputChange(e.target)}
                autoComplete="username"
                disabled={awaitingLogin}
                className={errors.user && 'login-wrong-field'}
              />
              {errors.user && <span className="login-error-hint">{errors.user}</span>}
            </label>
            <label className="auth-label">
              <span className="field-name">Пароль</span>
              <input
                type="password"
                value={formData.password}
                name="password"
                onChange={(e) => handleInputChange(e.target)}
                autoComplete={"current-password"}
                disabled={awaitingLogin}
                className={errors.password && 'login-wrong-field'}
              />
              {errors.password && <span className="login-error-hint">{errors.password}</span>}
            </label>
            {awaitingLogin ? (<div className="login-loader">
              <img src={`${process.env.PUBLIC_URL}/loader_5ms.svg`} alt="Loading..." className="login-loader-svg" />
            </div>) : (
              <button type="submit">Вход</button>
            )
            }
          </form>
          {error && <p>{error}</p>}
        </>
      )
      }

    </>
  );
}

export default Login;
