const Russian = () => {
  return {
    name: "gregorian_ru",
    months: [
      ["Январь", "янв"],
      ["Февраль", "фев"],
      ["Март", "мар"],
      ["Апрель", "апр"],
      ["Май", "май"],
      ["Июнь", "июн"],
      ["Июль", "июл"],
      ["Август", "авг"],
      ["Сентябрь", "сен"],
      ["Октябрь", "окт"],
      ["Ноябрь", "ноя"],
      ["Декабрь", "дек"],
    ],
    weekDays: [
      ["суббота", "сб"],
      ["воскресенье", "вс"],
      ["понедельник", "пн"],
      ["вторник", "вт"],
      ["среда", "ср"],
      ["четверг", "чт"],
      ["пятница", "пт"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["ДП", "дп"], // AM
      ["ПП", "пп"], // PM
    ],
  };
}

export default Russian;
