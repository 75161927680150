import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import Login from './Login';
import Register from './Register';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './AuthModal.scss'; // Подключение стилей, если они не подключены

const AuthModal = ({ isOpen, onRequestClose, onLogin }) => {
  const [activeTab, setActiveTab] = useState('login');
  const gliderRef = useRef(null);
  const activeTabRef = useRef(null);
  const tabsRef = useRef(null);

  const updateGlider = () => {
    if (activeTabRef.current && gliderRef.current) {
      const tabWidth = activeTabRef.current.offsetWidth;
      const tabLeft = activeTabRef.current.offsetLeft;
      const targetHeight = tabsRef.current.offsetHeight;
      gliderRef.current.style.width = `${tabWidth + 20}px`;
      gliderRef.current.style.left = `${tabLeft - 10}px`;
      gliderRef.current.style.height = `${targetHeight - 12}px`;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateGlider();
    };

    updateGlider();
    window.addEventListener('resize', handleResize);

    const observer = new MutationObserver(handleResize);
    observer.observe(document.body, { attributes: true, childList: true, subtree: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [activeTab]);



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="auth-modal"
      overlayClassName="auth-overlay"
    >
      <div className="tabs-wrapper-auth">
        <div className="tabs" ref={tabsRef}>
          <input
            type="radio"
            id="radio-login"
            name="tab-auth"
            checked={activeTab === 'login'}
            onChange={() => setActiveTab('login')}
            className="tab-hidden-input"
          />
          <label
            ref={activeTab === 'login' ? activeTabRef : null}
            className={`tab login ${activeTab === 'login' ? 'active' : ''}`}
            htmlFor="radio-login">
            Вход
          </label>

          <input
            type="radio"
            id="radio-register"
            name="tab-auth"
            checked={activeTab === 'register'}
            onChange={() => setActiveTab('register')}
            className="tab-hidden-input"
          />
          <label
            ref={activeTab === 'register' ? activeTabRef : null}
            className={`tab register ${activeTab === 'register' ? 'active' : ''}`}
            htmlFor="radio-register">
            Регистрация
          </label>

          <span className="glider" ref={gliderRef} />
        </div>
      </div>
      <>
        {activeTab === 'login' ? (
          <Login onLoginSuccess={onLogin} />
        ) : (
          <Register onRegisterSuccess={() => setActiveTab('login')} />
        )}
      </>
      <button className='close-modal-button' onClick={onRequestClose} ><IonIcon icon={closeOutline} /></button>
    </Modal>
  );
};

export default AuthModal;
