import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import Modal from 'react-modal';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { settingsOutline, exitOutline, personOutline } from 'ionicons/icons';
import './UserModal.scss';

const UserModal = ({ isOpen, onClose, onLogout, buttonRef }) => {
  const { firstname, middlename } = useContext(AuthContext);
  const [modalStyle, setModalStyle] = useState({});
  const ignoreClick = useRef(false);

  const handleResize = useCallback(() => {
    if (buttonRef?.current && isOpen) {
      const modalNode = document.querySelector('.user-modal');
      if (modalNode) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        setModalStyle({
          top: `${buttonRect.bottom}px`,
          left: `${buttonRect.right - modalNode.getBoundingClientRect().width}px`,
        });
      } else {
        requestAnimationFrame(handleResize);
      }
    }
  }, [buttonRef, isOpen]);

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(handleResize);

      ignoreClick.current = true;
      setTimeout(() => {
        ignoreClick.current = false;
      }, 100);

      const handleClickOutside = (event) => {
        if (ignoreClick.current) return;
        const modalNode = document.querySelector('.user-modal');
        if (modalNode && !modalNode.contains(event.target) && !buttonRef.current.contains(event.target)) {
          onClose();
        }
      };

      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isOpen, handleResize, onClose, buttonRef]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 12) {
      return 'Доброе утро';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Добрый день';
    } else if (currentHour >= 18 && currentHour < 22) {
      return 'Добрый вечер';
    } else {
      return 'Доброй ночи';
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="User Options"
      style={{ content: modalStyle, overlay: { backgroundColor: 'transparent' } }}
      shouldCloseOnOverlayClick={true}
      onBlur={onClose}
      className='user-modal'
      overlayClassName={'hidden-overlay'}
      ariaHideApp={false}
    >
      <div>
        <ul className='user-modal-list'>
          <li className='user-modal-item' ><span className='user-modal-username'> {getGreeting()}, {firstname} {middlename}!</span></li>
          <li className='user-modal-item'>
            <Link className='user-modal-link' to="/profile" onClick={onClose}>
              <IonIcon className='user-modal-icon' icon={personOutline} />
              <span className='user-modal-description'>Личный кабинет</span>
            </Link></li>
          <li className='user-modal-item'>
            <Link className='user-modal-link' to="/settings" onClick={onClose}>
              <IonIcon className='user-modal-icon' icon={settingsOutline} />
              <span className='user-modal-description'>Настройки</span>
            </Link>
          </li>
          <li className='user-modal-item'>
            <button className='user-modal-button' onClick={() => { onLogout(); onClose(); }}>
              <IonIcon className='user-modal-icon' icon={exitOutline} />
              <span className='user-modal-description'>Выход</span>
            </button>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default UserModal;
