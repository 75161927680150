import React, { useState, useCallback, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import Select from 'react-select';
import { useDrag } from 'react-dnd';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import 'react-resizable/css/styles.css';
import './Sidebar.css';

const Sidebar = ({ isOpen, templates, onClose }) => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [width, setWidth] = useState(500);
  const [height, setHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(500);
  const [maxWidth, setMaxWidth] = useState(300);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [searchActive, setSearchActive] = useState(false);

  const searchRef = useRef(null);
  const widthRef = useRef(width);
  const heightRef = useRef(height);

  const types = [
    { value: 'Лекция', label: 'Лекция' },
    { value: 'Лабораторная работа', label: 'Лабораторная работа' },
    { value: 'Практическое занятие', label: 'Практическое занятие' },
    { value: 'Консультация', label: 'Консультация' },
    { value: 'Курация', label: 'Курация' },
    { value: 'Зачёт', label: 'Зачёт' },
    { value: 'Экзамен', label: 'Экзамен' },
    { value: 'Дифференциальный зачёт', label: 'Дифференциальный зачёт' },
    { value: 'Коллоквиум', label: 'Коллоквиум' }
  ];

  const sortOptions = [
    { value: 'name ASC', label: 'По имени (возрастание)' },
    { value: 'name DESC', label: 'По имени (убывание)' }
  ];

  const limitOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: '2rem',
      borderColor: '#ced4da',
      minHeight: '38px',
      height: '38px',
      boxShadow: '0 0 1px 0 rgba(109, 93, 252, 0.15), 0 6px 12px 0 rgba(109, 93, 252, 0.15)',
      ':hover': {
        borderColor: 'var(--primary-color)',
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0px 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '38px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      padding: '8px 2px',
      cursor: 'pointer',
      ':hover': {
        color: '#e03f34',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: '2px',
      cursor: 'pointer',
      ':hover': {
        color: '#6d5dfc',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      borderBottomLeftRadius: '1rem',
      borderBottomRightRadius: '1rem',
      marginTop: '2px',
      padding: "0 0 0 4px ",
      overflow: 'hidden',

    }),
    menuList: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      paddingRight: '4px',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#6d5dfc',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#5b0eeb',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '6px 10px',
      borderRadius: '2rem',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? state.isFocused ? '#5b0eeb' : '#6d5dfc' : state.isFocused ? 'var(--secondary-color)' : 'white',
      color: state.isSelected ? 'white' : state.isFocused ? 'var(--primary-color)' : 'black',
      transition: 'background-color 0.3s ease',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '4px 0',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };

  const filteredTemplates = templates
    .filter(template =>
      (!typeFilter || template.type === typeFilter) &&
      (!search || template.name.toLowerCase().includes(search.toLowerCase()))
    )
    .sort((a, b) => {
      if (sort === 'name ASC') return a.name.localeCompare(b.name);
      if (sort === 'name DESC') return b.name.localeCompare(a.name);
      return 0;
    });

  const paginatedTemplates = filteredTemplates.slice((page - 1) * limit, page * limit);
  const totalPages = Math.ceil(filteredTemplates.length / limit);

  const containerRef = useRef(null);
  const draggableRef = useRef(null);
  const resizableRef = useRef(null);
  const contentRef = useRef(null);

  const scrollbarWidth = (function getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);
    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  })();

  const getCurrentScrollbarWidth = useCallback(() => {
    const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
    const bodyHeight = document.body.getBoundingClientRect().height;
    const bodyWidth = document.body.scrollWidth;
    const horisontalScrollbarSize = (bodyWidth > windowWidth ? scrollbarWidth : 0);
    const verticalScrollbarSize = (bodyHeight > windowHeight ? scrollbarWidth : 0);

    return { horisontal: horisontalScrollbarSize, vertical: verticalScrollbarSize };
  }, [scrollbarWidth]);

  const setStartSidebarPosition = useCallback(() => {
    setPosition({ x: window.innerWidth - widthRef.current - getCurrentScrollbarWidth().vertical, y: 0 });
  }, [getCurrentScrollbarWidth]);

  const setMaxContainerSize = useCallback(() => {
    if (draggableRef.current) {
      const rect = draggableRef.current.getBoundingClientRect();
      const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
      setMaxHeight(windowHeight - rect.top - getCurrentScrollbarWidth().horisontal);
      setMaxWidth(windowWidth - rect.left - getCurrentScrollbarWidth().vertical);
    }
  }, [getCurrentScrollbarWidth]);

  const handleWindowResize = useCallback(() => {
    setStartSidebarPosition();
    const { innerHeight: windowHeight, innerWidth: windowWidth } = window;

    if (heightRef.current >= windowHeight && windowHeight > 500) {
      setHeight(windowHeight);
    }
    if (widthRef.current >= windowWidth && windowWidth > 300) {
      setWidth(windowWidth);
    }
  }, [setStartSidebarPosition]);

  const handleResize = (event, { size }) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  const handleDrag = useCallback((event, data) => {
    if (draggableRef.current) {
      setPosition({ x: data.x, y: data.y });
      setMaxContainerSize();
    }
  }, [setMaxContainerSize]);

  useEffect(() => {
    widthRef.current = width;
  }, [width]);

  useEffect(() => {
    heightRef.current = height;
  }, [height]);

  useEffect(() => {
    setStartSidebarPosition();
    setTimeout(() => {
      setMaxContainerSize();
    }, 0);
  }, [setStartSidebarPosition, setMaxContainerSize]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [getCurrentScrollbarWidth, handleWindowResize]);

  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      setSearchActive(false);
    }
  }, [search]);


  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  useEffect(() => {
    const contentElement = contentRef.current;

    if (contentElement) {
      // Создаем наблюдателя за изменениями размеров контента
      const resizeObserver = new ResizeObserver(() => {
        setHeight(contentElement.scrollHeight);
      });

      // Подключаем наблюдателя к контенту
      resizeObserver.observe(contentElement);

      // Устанавливаем начальную высоту
      setHeight(contentElement.scrollHeight);

      // Очищаем наблюдателя при размонтировании компонента
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [templates]);

  return (
    <div ref={containerRef} className="sidebar-container">
      <Draggable
        nodeRef={draggableRef}
        handle=".sidebar-header"
        bounds="parent"
        defaultPosition={{ x: window.innerWidth - widthRef.current, y: 0 }}
        position={position}
        onDrag={handleDrag}
      >
        <div ref={draggableRef} className="draggable-box" style={{ width: width, height: height }}>
          <ResizableBox
            ref={resizableRef}
            width={width}
            height={height}
            minConstraints={[300, 300]}
            maxConstraints={[maxWidth, maxHeight]}
            className={`sidebar ${isOpen ? 'open' : ''}`}
            onResize={handleResize}
          >
            <div ref={contentRef}>
              <div className="sidebar-header">
                <h4>Шаблоны занятий</h4>
                <button className="close-button" onClick={onClose}>×</button>
              </div>
              <div className="sidebar-content">
                <div className='sidebar-actions-list'>
                  <button
                    className='search-bar-button'
                    onClick={toggleSearch}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <IonIcon className='search-bar-icon' icon={searchOutline} />
                  </button>
                  <input
                    type="text"
                    placeholder="Поиск"
                    className='search-bar'
                    value={search}
                    ref={searchRef}
                    onChange={(e) => setSearch(e.target.value)}
                    onBlur={handleSearchBlur}
                  />
                  <Select
                    value={sortOptions.find(option => option.value === sort)}
                    styles={customStyles}
                    onChange={(selectedOption) => setSort(selectedOption ? selectedOption.value : '')}
                    options={sortOptions}
                    placeholder="Сортировка"
                    isClearable
                  />
                  <Select
                    value={types.find(option => option.value === typeFilter)}
                    styles={customStyles}
                    onChange={(selectedOption) => setTypeFilter(selectedOption ? selectedOption.value : '')}
                    options={types}
                    placeholder="Фильтр по типу"
                    isClearable
                  />
                </div>
                <ul className='class-template-list'>
                  {paginatedTemplates.map(template => (
                    <DraggableTemplate key={template.id} template={template} />
                  ))}
                </ul>
                <div className="navigation">
                  <button className="button-back" disabled={page <= 1} onClick={() => setPage(page - 1)}><IonIcon icon={arrowBackOutline} /></button>
                  <span>Страница {page} из {totalPages}</span>
                  <button className="button-forward" disabled={page >= totalPages} onClick={() => setPage(page + 1)}><IonIcon icon={arrowForwardOutline} /></button>
                </div>
                <div className='pagination'>
                  <label>Количество строк на странице </label>
                  <Select
                    value={limitOptions.find(option => option.value === limit)}
                    styles={customStyles}
                    onChange={(selectedOption) => setLimit(selectedOption ? selectedOption.value : 15)}
                    options={limitOptions}
                    placeholder=""

                  />
                </div>
              </div>
            </div>
          </ResizableBox>
        </div>
      </Draggable>
    </div>
  );
};

const DraggableTemplate = ({ template }) => {
  const [, drag] = useDrag({
    type: 'CLASS_TEMPLATE',
    item: { template },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <li className='class-template-item' ref={drag} >
      <strong>{template.name}</strong> ({template.duration})<br />
      <em>{template.type}</em><br />
      <small>{template.description}</small>
    </li>
  );
};

export default Sidebar;