import React, { createContext, useState, useEffect, useCallback } from 'react';
import jwtDecode from 'jwt-decode';
import api from '../services/api';
import '../assets/css/profile.scss';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [role, setRole] = useState('guest');
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  const openAuthModal = useCallback(() => setIsAuthModalOpen(true), []);
  const closeAuthModal = useCallback(() => setIsAuthModalOpen(false), []);

  const logout = useCallback(() => {
    setIsLogin(false);
    setRole('guest');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userData');
  }, []);

  const decodeRole = useCallback((token) => {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role) {
        setRole(decodedToken.role);
      }
    }
  }, []);

  const handleLogin = useCallback((token, id, firstname, middlename, lastname, email, emailConfirmed) => {
    setIsLogin(true);
    closeAuthModal();
    decodeRole(token);

    const userData = { firstname, middlename, lastname, id, email, emailConfirmed };
    localStorage.setItem('token', token);
    localStorage.setItem('userData', JSON.stringify(userData));

    setUserId(id);
    setFirstname(firstname);
    setMiddlename(middlename);
    setLastname(lastname);
    setEmail(email);
    setEmailConfirmed(emailConfirmed);
  }, [closeAuthModal, decodeRole]);

  const updateUserData = useCallback((userData) => {
    setFirstname(userData.firstname);
    setMiddlename(userData.middlename);
    setLastname(userData.lastname);
    // Обновляем только те поля, которые могут измениться при редактировании профиля
    // email и emailConfirmed обычно не меняются при простом редактировании профиля
  }, []);

  const handleLogout = useCallback(() => logout(), [logout]);

  const verifyToken = useCallback(async () => {
    try {
      const response = await api.get('/u/verify-token');
      if (response.data.valid) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
        logout();
      }
    } catch (err) {
      setIsLogin(false);
      logout();
    }
    setIsLoading(false);
  }, [logout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && Object.keys(userData).length > 0) {
        setIsLogin(true);
        decodeRole(token);

        setUserId(userData.id);
        setFirstname(userData.firstname);
        setMiddlename(userData.middlename);
        setLastname(userData.lastname);
        setEmail(userData.email);
        setEmailConfirmed(userData.emailConfirmed);

        verifyToken();
      } else {
        handleLogout();
      }
    } else {
      setIsLoading(false);
    }
  }, [verifyToken, decodeRole, handleLogout]);

  return (
    <AuthContext.Provider value={{
      isLogin,
      role,
      handleLogin,
      handleLogout,
      openAuthModal,
      closeAuthModal,
      isAuthModalOpen,
      isLoading,
      firstname,
      middlename,
      lastname,
      userId,
      email,
      emailConfirmed,
      updateUserData,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
