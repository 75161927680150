import React, { useRef } from 'react';
import './GroupActions.css';
import { IonIcon } from '@ionic/react';
import { trashOutline, createOutline } from 'ionicons/icons';

const GroupActions = ({ group, openEditModal, onDeleteGroup }) => {
  const containerRef = useRef(null);

  return (
    <th ref={containerRef}>
      <div className='table-header-wrapper' >
        <span className='group-name'>{group.name}</span>
        <button onClick={(event) => openEditModal(group, event, containerRef.current)} className='edit-button'><IonIcon icon={createOutline} /></button>
        <button onClick={() => onDeleteGroup(group.id)} className='remove-button'><IonIcon icon={trashOutline} /></button>
      </div >
    </th>
  );
};

export default GroupActions;
