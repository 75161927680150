// customSelectStyles.js
const customMultiEditorStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    zIndex: '1',
    alignItems: 'center',
    display: 'flex',
    minWidth: '250px',
    backgroundColor: 'var(--secondary-color)',
    padding: '8px',
    borderRadius: '24px',
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '18px',
    border: 'none',
    outline: '1px solid #ced4da',
    overflow: 'hidden',
    boxShadow: '0 0 1px 0 rgba(109, 93, 252, 0.15), 0 6px 12px 0 rgba(109, 93, 252, 0.15)',
    ':hover': {
      outline: '1px solid var(--primary-color)',
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '4px',
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: '18px',
    backgroundColor: 'var(--secondary-color)',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--black)',
    backgroundColor: 'var(--secondary-color)',
    borderRadius: '18px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: '18px',
    cursor: 'pointer',
    transition: 'color .25s ease-in, background-color .25s ease-in',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    paddingLeft: '4px',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    alignItems: 'flex-start',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
    cursor: 'pointer',
    ':hover': {
      color: '#e03f34',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingLeft: '2px',
    cursor: 'pointer',
    ':hover': {
      color: '#6d5dfc',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    marginTop: '-6px',
    padding: "0",
    paddingLeft: '2px',
    overflow: 'hidden',
    width: 'calc(100% - 20px)',
  }),
  menuList: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    paddingRight: '4px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#6d5dfc',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#5b0eeb',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '6px 10px',
    borderRadius: '18px',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? state.isFocused ? '#5b0eeb' : '#6d5dfc' : state.isFocused ? 'var(--secondary-color)' : 'white',
    color: state.isSelected ? 'white' : state.isFocused ? 'var(--primary-color)' : 'black',
    transition: 'background-color 0.3s ease',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '4px 0',
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

export default customMultiEditorStyles;
