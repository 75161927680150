import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FacultyEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/FacultyEditor';
import SpecialityEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/SpecialityEditor';
import DepartmentEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/DepartmentEditor';
import CourseEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/CourseEditor';
import EducatorEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/EducatorEditor';
import SemesterEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/SemesterEditor';
import CourseScheduleEditor from '../components/Schedule/ScheduleEditor/CourseScheduleEditor/CourseScheduleEditor';
import ClassTemplateEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/ClassTemplateEditor';
import AuditoriumEditor from '../components/Schedule/ScheduleEditor/dictionaryEditors/AuditoriumEditor';
import { AuthContext } from '../context/AuthContext';
import LoaderContext from '../context/LoaderContext';
import '../assets/css/ScheduleEditor.scss';

const ScheduleEditor = () => {
  const [activeTab, setActiveTab] = useState('faculty');
  const { isLogin, role, openAuthModal, isLoading } = useContext(AuthContext);
  const [editor, setEditor] = useState(false);
  const navigate = useNavigate();
  const { showLoader, hideLoader, setLoadingMessage } = useContext(LoaderContext);
  const gliderRef = useRef(null);
  const activeTabRef = useRef(null);
  const tabsRef = useRef(null);

  useEffect(() => {
    const isEditor = ['editor', 'admin'].includes(role);
    setEditor(isEditor);

    if (isLoading) {
      setLoadingMessage('Редактор расписания загружается');
      showLoader();
    } else {
      hideLoader();
      if (!isLogin) {
        openAuthModal();
      } else if (!isEditor) {
        navigate('/403');
      }
    }
    console.log({ role: role, isLoading: isLoading, editor: isEditor, isLogin: isLogin });
  }, [isLogin, role, isLoading, navigate, openAuthModal, showLoader, hideLoader, setLoadingMessage]);

  const updateGlider = () => {
    if (activeTabRef.current && gliderRef.current) {
      const tabWidth = activeTabRef.current.offsetWidth;
      const tabLeft = activeTabRef.current.offsetLeft;
      const targetHeight = tabsRef.current.offsetHeight;
      gliderRef.current.style.width = `${tabWidth + 20}px`;
      gliderRef.current.style.left = `${tabLeft - 10}px`;
      gliderRef.current.style.height = `${targetHeight - 12}px`;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateGlider();
    };

    updateGlider();
    window.addEventListener('resize', handleResize);

    const observer = new MutationObserver(handleResize);
    observer.observe(document.body, { attributes: true, childList: true, subtree: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [activeTab]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'speciality':
        return <SpecialityEditor />;
      case 'course':
        return <CourseEditor />;
      case 'courseSchedule':
        return <CourseScheduleEditor />;
      case 'educator':
        return <EducatorEditor />;
      case 'semester':
        return <SemesterEditor />;
      case 'department':
        return <DepartmentEditor />;
      case 'classTemplate':
        return <ClassTemplateEditor />;
      case 'auditorium':
        return <AuditoriumEditor />
      default:
        return <FacultyEditor />;
    }
  };

  const tabs = [
    { id: 'faculty', label: 'Факультеты' },
    { id: 'department', label: 'Кафедры' },
    { id: 'speciality', label: 'Специальности' },
    { id: 'course', label: 'Курсы' },
    { id: 'educator', label: 'Преподаватели' },
    { id: 'auditorium', label: 'Аудитории' },
    { id: 'semester', label: 'Учебные семестры' },
    { id: 'classTemplate', label: 'Шаблоны занятий' },
    { id: 'courseSchedule', label: 'Расписания' },

  ];

  return (
    <>
      {isLoading ? null : (
        isLogin && editor && (
          <div>
            <div className='container'>

              <div className='tabs-wrapper'>
                <div className="tabs" ref={tabsRef}>
                  {tabs.map(tab => (
                    <React.Fragment key={tab.id}>
                      <input
                        type="radio"
                        id={`radio-${tab.id}`}
                        name={`tab-${tab.id}`}
                        checked={activeTab === tab.id}
                        onChange={() => setActiveTab(tab.id)}
                        className='tab-hidden-input'
                      />
                      <label
                        ref={activeTab === tab.id ? activeTabRef : null}
                        className={`tab ${tab.id} ${activeTab === tab.id ? 'active' : ''}`}
                        htmlFor={`radio-${tab.id}`}>
                        {tab.label}
                      </label>
                    </React.Fragment>
                  ))}
                  <span className="glider" ref={gliderRef} />
                </div>
              </div>
            </div>
            <div className="tab-content">
              {renderTabContent()}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ScheduleEditor;
