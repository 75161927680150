import React, { useContext, useEffect, useState } from 'react';
import LoaderContext from '../context/LoaderContext';
import { AuthContext } from '../context/AuthContext';
import { IonIcon } from '@ionic/react';
import { closeOutline, createOutline } from 'ionicons/icons';
import { toast } from "react-toastify";
import api from '../services/api';

const Profile = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { isLogin, isLoading, role, userId, firstname, middlename, lastname, email, emailConfirmed, openAuthModal, updateUserData } = useContext(AuthContext);
  const [editingProfile, setEditingProfile] = useState(false);
  const notify = (message, type = null, params = null) => type ? toast[type](message, params) : toast(message, params);
  const [editUser, setEditUser] = useState({
    userId: '',
    firstname: '',
    middlename: '',
    lastname: '',
    email: ''
  });

  useEffect(() => {
    if (!isLoading) {
      if (!isLogin) {
        openAuthModal();
      } else {
        setEditUser({
          userId,
          firstname,
          middlename,
          lastname,
          email
        });
      }
    }
  }, [isLogin, isLoading, openAuthModal, userId, firstname, middlename, lastname, email]);

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, hideLoader, showLoader]);

  const handleEditProfile = async (e) => {
    e.preventDefault();
    try {
      showLoader();
      await api.put('/u/profile', editUser);

      // Обновляем userData в localStorage
      const updatedUserData = {
        ...JSON.parse(localStorage.getItem('userData')),
        firstname: editUser.firstname,
        middlename: editUser.middlename,
        lastname: editUser.lastname,
      };
      localStorage.setItem('userData', JSON.stringify(updatedUserData));

      // Обновляем контекст аутентификации
      updateUserData(updatedUserData);

      notify("Профиль обновлён", "success");
      setEditingProfile(false);
    } catch (error) {
      notify("Ошибка при обновлении профиля", "error");
      console.error("Ошибка при обновлении профиля", error);
    } finally {
      hideLoader();
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser(prev => ({ ...prev, [name]: value }));
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLogin) {
    return null;
  }

  return (
    <div className='container'>
      <div className='content-area profile'>
        <div className='profile-headers'>
          <h1 className='profile-title'>Моя учётная запись</h1>
          <span className='profile-role'>{role === "admin" ? "Администратор" : role === "editor" ? "Редактор" : ""}</span>
        </div>
        <form className='profile-edit-form' onSubmit={handleEditProfile}>
          <button type="button" className='profile-edit-button' onClick={() => setEditingProfile(!editingProfile)}>
            <span>{editingProfile ? 'Отмена' : 'Редактировать'}</span>
            <IonIcon className={`profile-edit-icon ${editingProfile ? 'editing' : ''}`} icon={editingProfile ? closeOutline : createOutline} />
          </button>
          {['firstname', 'middlename', 'lastname'].map((field) => (
            <label key={field} className='profile-edit-label'>
              <span className='profile-edit-input-title'>{field === 'firstname' ? 'Имя' : field === 'middlename' ? 'Отчество' : 'Фамилия'}</span>
              <input
                type='text'
                name={field}
                disabled={!editingProfile}
                value={editUser[field]}
                placeholder={field === 'firstname' ? 'Имя' : field === 'middlename' ? 'Отчество' : 'Фамилия'}
                autoComplete={field}
                onChange={handleInputChange}
              />
            </label>
          ))}
          <label className='profile-edit-label'>
            <span className='profile-edit-input-title'>Электронная почта</span>
            <input
              className={`profile-email ${!emailConfirmed ? 'not-confirmed' : ''}`}
              type='email'
              disabled={true}
              value={editUser.email}
            />
            {!emailConfirmed && (<span>Электронная почта не подтверждена!</span>)}
          </label>
          {editingProfile && <button type='submit'>Сохранить</button>}
        </form>
      </div>
    </div>
  );
};

export default Profile;
