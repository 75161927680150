import React, { useState, useEffect, useCallback } from 'react';
import './AcademicCalendar.css';

const AcademicCalendar = () => {
    const [date, setDate] = useState(new Date());
    const [semester, setSemester] = useState(null);
    const [weekNumber, setWeekNumber] = useState(null);

    const calculateAcademicInfo = useCallback((currentDate) => {
        const startOfFirstSemester = getFirstWorkingDayOfSeptember(currentDate.getFullYear());
        const startOfSecondSemester = getFirstWorkingDayOfFebruary(currentDate.getFullYear());

        const endOfFirstSemester = new Date(currentDate.getFullYear() + 1, 0, 31);
        const endOfSecondSemester = new Date(currentDate.getFullYear(), 5, 30);

        if (currentDate >= startOfFirstSemester && currentDate <= endOfFirstSemester) {
            setSemester(1);
            setWeekNumber(calculateWeekNumber(startOfFirstSemester, currentDate));
        } else if (currentDate >= startOfSecondSemester && currentDate <= endOfSecondSemester) {
            setSemester(2);
            setWeekNumber(calculateWeekNumber(startOfSecondSemester, currentDate));
        } else {
            setSemester(null);
            setWeekNumber(null);
        }
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        setDate(currentDate);
        calculateAcademicInfo(currentDate);
    }, [calculateAcademicInfo]);

    const getFirstWorkingDayOfSeptember = (year) => {
        let date = new Date(year, 8, 1);
        while (date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }
        return date;
    };

    const getFirstWorkingDayOfFebruary = (year) => {
        let date = new Date(year, 1, 1);
        while (date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }
        return date;
    };

    const calculateWeekNumber = (startDate, currentDate) => {
        const oneWeek = 1000 * 60 * 60 * 24 * 7;
        return Math.ceil((currentDate - startDate + 1) / oneWeek);
    };

    const getMonthAbbreviation = (date) => {
        const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Мая', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
        return months[date.getMonth()];
    };

    return (
        <div className='academic-calendar'>
            <div className='calendar-wrapper'>
                <span className='calendar-day'>{date.getDate()}</span>
                <span className='calendar-month'>{getMonthAbbreviation(date)}</span>
            </div>
            {semester ? (
                <div className='semester-info'>
                    {/* <span>{semester} учебный семестр</span> */}
                    <span>{weekNumber} учебная неделя</span>
                </div>
            ) : (
                <span className='summer-vacation'>Каникулы</span>
            )}
        </div>
    );
};

export default AcademicCalendar;
