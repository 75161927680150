import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import api from '../services/api';
import Skeleton from 'react-loading-skeleton';
import '../assets/css/course.scss';

const CoursePage = () => {
  const { facultyId, specialityId } = useParams();
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const [loadingFaculty, setLoadingFaculty] = useState(false);
  const [faculty, setFaculty] = useState({ id: 0, name: '', logo_url: '' });
  const [loadingSpeciality, setLoadingSpeciality] = useState(false);
  const [speciality, setSpeciality] = useState({ id: 0, name: '', identifier: '00.00.00' });

  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        setLoadingFaculty(true);
        const response = await api.get(`/faculty/all-by-foreign-key/id/${facultyId}`);
        setFaculty(response.data[0]);
      } catch (error) {
        console.error('Ошибка при загрузке данных о факультете', error);
      } finally {
        setLoadingFaculty(false);
      }
    };

    fetchFaculty();
  }, [facultyId]);

  useEffect(() => {
    const fetchSpeciality = async () => {
      try {
        setLoadingSpeciality(true);
        const response = await api.get(`/speciality/all-by-foreign-key/id/${specialityId}`);
        setSpeciality(response.data[0]);
      } catch (error) {
        console.error('Ошибка при загрузке данных о специальности', error);
      } finally {
        setLoadingSpeciality(false);
      }
    };

    fetchSpeciality();
  }, [specialityId]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoadingCourses(true);
        const response = await api.get(`/course/all-by-foreign-key/speciality_id/${specialityId}`);
        setCourses(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке курсов', error);
      } finally {
        setLoadingCourses(false);
      }
    };

    fetchCourses();
  }, [facultyId, specialityId]);

  const gridClass = courses.length > 0 ? `grid-${courses.length}` : 'grid-default';

  return (
    <div className='container'>
      <div className='course-titles'>
        {loadingFaculty ? (
          <Skeleton className='course-faculty-name' width={300} height={48} />
        ) : (
          <Link to={`/faculty/${facultyId}/specialities`} className='course-faculty-name'>
            {faculty.name}
          </Link>
        )}
        <IonIcon className='course-struct-icon' icon={chevronForwardOutline} />
        {loadingSpeciality ? (
          <Skeleton className='course-speciality-name' width={200} height={48} />
        ) : (
          <h2 className='course-speciality-name'>{speciality.identifier} {speciality.name}</h2>
        )}
      </div>
      <ul className={`course-list ${gridClass}`}>
        {loadingCourses ? (
          Array(5).fill().map((_, index) => (
            <li key={index} className="course-item">
              <Skeleton className='course-link' height={150} />
            </li>
          ))
        ) : (
          courses.map((course) => (
            <li key={course.id} className='course-item'>
              <Link className='course-link' to={`/faculty/${facultyId}/speciality/${specialityId}/course/${course.id}/groups`}>
                {course.name}
              </Link>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default CoursePage;
