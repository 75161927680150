import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../../../services/api';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import { searchOutline, arrowUpOutline, arrowDownOutline, swapVerticalOutline, checkmarkOutline, closeOutline, addOutline, createOutline, trashOutline, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import './css/UniversalEditor.scss';
import paginationStyles from './css/paginationSelectStyles';

const FacultyEditor = () => {
  const [faculties, setFaculties] = useState([]);
  const [newFaculty, setNewFaculty] = useState('');
  const [newFacultyLogo, setNewFacultyLogo] = useState(null);
  const [newFacultyLogoPreview, setNewFacultyLogoPreview] = useState(null);
  const [editFacultyId, setEditFacultyId] = useState(null);
  const [editFacultyName, setEditFacultyName] = useState('');
  const [editFacultyLogo, setEditFacultyLogo] = useState(null);
  const [editFacultyLogoPreview, setEditFacultyLogoPreview] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [loading, setLoading] = useState(true); // Новое состояние для отслеживания загрузки
  const [searchFields] = useState(['name']);
  const [searchActive, setSearchActive] = useState(false);
  const searchRef = useRef(null);
  const buttonRef = useRef(null);
  const editImageWrapperRef = useRef(null);

  const paginationOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const baseURL = process.env.REACT_APP_BASE_URL;

  const fetchFaculties = useCallback(async () => {
    try {
      setLoading(true); // Начинаем загрузку
      let req = `/faculty?page=${page}&limit=${limit}`;
      if (search && searchFields) {
        searchFields.forEach(field => {
          req += `&searchFields=${field}`;
        });
        req += `&search=${search}`;
      }
      if (sort) req += `&sort=${sort}`;

      const response = await api.get(req);
      setFaculties(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      if (page > response.data.pagination.totalPages) {
        setPage(1);
      }
    } catch (error) {
      console.error('Ошибка при загрузке факультетов', error);
    } finally {
      setLoading(false); // Завершаем загрузку
    }
  }, [limit, page, search, sort, searchFields]);

  useEffect(() => {
    fetchFaculties();
  }, [fetchFaculties]);

  const handleSearchChange = (e) => { setSearch(e.target.value); };

  const addFaculty = async () => {
    const formData = new FormData();
    formData.append('name', newFaculty);
    if (newFacultyLogo) {
      formData.append('logo', newFacultyLogo);
    }

    try {
      await api.post('/faculty', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Факультет добавлен!');
      fetchFaculties();
      setNewFaculty('');
      setNewFacultyLogo(null);
      setNewFacultyLogoPreview(null); // Очистить превью
      setShowAddForm(false);
    } catch (error) {
      console.error('Ошибка при добавлении факультета', error);
      toast.error('Ошибка при добавлении факультета');
    }
  };

  const deleteFaculty = async (id) => {
    try {
      await api.delete(`/faculty/${id}`);
      toast.success('Факультет удален!');
      fetchFaculties();
    } catch (error) {
      console.error('Ошибка при удалении факультета', error);
      toast.error('Ошибка при удалении факультета');
    }
  };

  const startEditFaculty = (id, name, logoUrl) => {
    setEditFacultyId(id);
    setEditFacultyName(name);
    setEditFacultyLogo(null);
    setEditFacultyLogoPreview(logoUrl ? baseURL + logoUrl : null);
  };

  const playCloseEditDropzone = () => {
    editImageWrapperRef.current.classList.add("close");
  };

  const cancelEditFaculty = () => {
    playCloseEditDropzone();
    setTimeout(() => {
      setEditFacultyId(null);
      setEditFacultyName('');
      setEditFacultyLogo(null);
      setEditFacultyLogoPreview(null);
    }, 250);
  };

  const updateFaculty = async () => {
    const formData = new FormData();
    formData.append('name', editFacultyName);
    if (editFacultyLogo) {
      formData.append('logo', editFacultyLogo);
    } else if (!editFacultyLogoPreview) {
      formData.append('remove_logo', true);
    }

    try {
      await api.put(`/faculty/${editFacultyId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Факультет обновлен!');
      fetchFaculties();
      cancelEditFaculty();
    } catch (error) {
      console.error('Ошибка при обновлении факультета', error);
      toast.error('Ошибка при обновлении факультета');
    }
  };

  const toggleSort = () => {
    if (sort === 'name ASC') {
      setSort('name DESC');
    } else if (sort === 'name DESC') {
      setSort('');
    } else {
      setSort('name ASC');
    }
  };

  const handleSearchFocus = useCallback(() => {
    const searchElement = searchRef.current;
    searchElement.focus();
    searchElement.style.width = '100%';
    searchElement.style.minWidth = '200px';
    setSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!search) {
      const searchElement = searchRef.current;
      searchElement.blur();
      searchElement.style.width = '0';
      searchElement.style.minWidth = '0';
      setSearchActive(false);
    }
  }, [search]);

  const toggleSearch = useCallback(() => {
    !searchActive ? handleSearchFocus() : handleSearchBlur();
  }, [handleSearchFocus, handleSearchBlur, searchActive]);

  const handleDropNew = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setNewFacultyLogo(file);
      setNewFacultyLogoPreview(URL.createObjectURL(file));
    }
  }, []);

  const handleDropEdit = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setEditFacultyLogo(file);
      setEditFacultyLogoPreview(URL.createObjectURL(file));
    }
  }, []);

  const toggleAddForm = () => {
    setIsAnimating(true);

    if (!showAddForm) {
      // Открытие формы
      setTimeout(() => {
        setShowAddForm(true);
        setIsAnimating(false);
      }, 150);
    } else {
      // Закрытие формы
      setIsClosing(true);
      setShowAddForm(false);
      setIsAnimating(false);
      setIsClosing(false);
    }
  };

  useEffect(() => {
    const updateModalPosition = () => {
      if (buttonRef.current && showAddForm) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const modalContent = document.querySelector('.editor-add-modal');

        if (modalContent) {
          modalContent.style.top = `${buttonRect.bottom}px`;
          modalContent.style.left = `${buttonRect.left}px`;
        }
      }
    };

    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    updateModalPosition();

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, [showAddForm]);

  return (
    <div className='container'>
      <div className='actions-list'>
        <button
          ref={buttonRef}
          onClick={toggleAddForm}
          className={`add-entity-button ${showAddForm || isAnimating ? 'modal-open' : ''}`}
        >
          <span className="add-entity-button-text">
            <span className="text-add">Добавить факультет</span>
            <span className="text-cancel">Отмена</span>
          </span>
          <IonIcon className={`add-entity-button-icon ${showAddForm || isAnimating ? 'modal-open' : ''}`} icon={addOutline} />
        </button>
        <Modal
          isOpen={showAddForm}
          onRequestClose={toggleAddForm}
          contentLabel="Добавить факультет"
          className={`editor-add-modal ${isClosing ? 'is-closing' : ''}`}
          overlayClassName="editor-add-overlay"
          closeTimeoutMS={300}
          style={{
            content: {
              top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : '50%',
              left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : '50%',
              transform: 'translate(0, 0)',
            },
          }}
        >
          <h3>Добавить факультет</h3>
          <div className='editor-add-modal-form'>
            <div className='editor-add-modal-form-input-wrapper'>
              <input
                type="text"
                placeholder="Название факультета"
                value={newFaculty}
                onChange={(e) => setNewFaculty(e.target.value)}
              />
              {newFaculty && <button className='editor-add-modal-form-input-wrapper-remove-title' onClick={() => { setNewFaculty('') }} onMouseDown={(e) => { e.preventDefault() }} ><IonIcon icon={closeOutline} /></button>}
            </div>
            <Dropzone onDrop={handleDropNew} maxFiles={1} accept={{ 'image/*': [] }}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />

                  {newFacultyLogoPreview ? (
                    <img src={newFacultyLogoPreview} alt="Preview" className="image-preview" />
                  ) : (<p>Перетащите логотип сюда или нажмите для выбора файла</p>)}
                  {newFacultyLogoPreview && <button
                    className='dropzone-remove-image-button'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setNewFacultyLogo(null);
                      setNewFacultyLogoPreview(null);
                    }}><IonIcon icon={closeOutline} />
                  </button>}
                </div>
              )}
            </Dropzone>
            <button onClick={addFaculty} disabled={!newFaculty} className='editor-add-modal-form-add-button'><span className='editor-add-modal-form-add-button-text'>Добавить</span><IonIcon className='editor-add-modal-form-add-button-icon' icon={checkmarkOutline} /></button>
          </div>
        </Modal>
        <div className='arrange-list'>
          <button
            className='search-bar-button'
            onClick={toggleSearch}
            onMouseDown={(e) => e.preventDefault()}
          >
            <IonIcon className='search-bar-icon' icon={searchOutline} />
          </button>
          <input
            type="text"
            placeholder="Поиск"
            className='search-bar'
            value={search}
            ref={searchRef}
            onChange={handleSearchChange}
            onBlur={handleSearchBlur}
          />
          {searchActive && search && (
            <button onClick={() => { setSearch(''); searchRef.current.focus() }} onMouseDown={(e) => e.preventDefault()} className='search-bar-button-clear'><IonIcon className='search-bar-icon-clear' icon={closeOutline} /></button>
          )}
          <button className={`filter-button ${sort !== '' ? 'active' : ''}`} onClick={toggleSort}>А-Я
            {sort === 'name ASC' && (<IonIcon className='filter-button-icon' icon={arrowUpOutline} />)}
            {sort === 'name DESC' && (<IonIcon className='filter-button-icon' icon={arrowDownOutline} />)}
            {sort === '' && (<IonIcon className='filter-button-icon' icon={swapVerticalOutline} />)}
          </button>
          <button className='fetch-entities' onClick={fetchFaculties}><IonIcon icon={checkmarkOutline} /></button>
        </div>
      </div >
      <div className='content-area'>
        <ul className='entity-list'>
          <li className='entity-header faculty'>
            <span>Название</span>
            <span>Логотип</span>
            <span className='entity-header-actions'>Действия</span>
          </li>
          {loading ? (
            Array(5).fill().map((_, index) => (
              <li key={index} className="entity-item faculty">
                <span className='entity-item-name'><Skeleton width={200} /></span>
                <div className='entity-item-logo'><Skeleton width={40} height={40} circle={true} /></div>
                <Skeleton width={30} height={30} />
                <Skeleton width={30} height={30} />
              </li>
            ))
          ) : (
            faculties.map(faculty => (
              <li
                className={`entity-item faculty ${faculty.id === editFacultyId ? 'editing' : ''}`}
                key={faculty.id}>
                {faculty.id === editFacultyId ? (
                  <>
                    <input
                      placeholder='Название факультета'
                      type="text"
                      value={editFacultyName}
                      onChange={(e) => setEditFacultyName(e.target.value)}
                    />
                    <div ref={editImageWrapperRef} className='entity-item-dropzone-wrapper'>
                      <Dropzone onDrop={handleDropEdit} maxFiles={1} accept={{ 'image/*': [] }}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input  {...getInputProps()} />
                            {!editFacultyLogoPreview ? <p>Перетащите логотип сюда или нажмите для выбора файла</p> :
                              (
                                <img src={editFacultyLogoPreview} alt="Preview" className="image-preview" />
                              )}
                            {editFacultyLogoPreview && <button className='dropzone-remove-image-button' onClick={(e) => { setEditFacultyLogoPreview(null); setEditFacultyLogo(null); e.preventDefault(); e.stopPropagation() }}>
                              <IonIcon icon={closeOutline} />
                            </button>}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <button className='entity-item-button done' onClick={updateFaculty}><IonIcon icon={checkmarkOutline} /></button>
                    <button className='entity-item-button cancel' onClick={cancelEditFaculty}><IonIcon icon={closeOutline} /></button>
                  </>
                ) : (
                  <>
                    <span className='entity-item-name'>{faculty.name}</span>
                    <div className='entity-item-logo'>{faculty.logo_url && <img src={baseURL + faculty.logo_url} alt={faculty.name} width="40" />}</div>
                    <button className='entity-item-button edit' onClick={() => startEditFaculty(faculty.id, faculty.name, faculty.logo_url)}><IonIcon icon={createOutline} /></button>
                    <button className='entity-item-button delete' onClick={() => deleteFaculty(faculty.id)}><IonIcon icon={trashOutline} /></button>
                  </>
                )}
              </li>
            ))
          )}
        </ul>
        <div className='page-navigation'>
          <button className='button-back' disabled={page <= 1} onClick={() => setPage(page - 1)}>< IonIcon icon={arrowBackOutline} /></button>
          <span>Страница {page} из {totalPages}</span>
          <button className='button-forward' disabled={page >= totalPages} onClick={() => setPage(page + 1)}>< IonIcon icon={arrowForwardOutline} /></button>
        </div>
        <div className='page-pagination'>
          <label>Количество строк на странице</label>
          <Select
            styles={paginationStyles}
            value={paginationOptions.find(option => option.value === limit)}
            onChange={(selectedOption) => { setLimit(selectedOption.value) }}
            options={paginationOptions}
            isSearchable={false}
          />
        </div>
      </div>
    </div >
  );
};

export default FacultyEditor;
