import React from 'react';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './Modal.css';

const Modal = ({ title, onClose, children, style }) => {
  return (
    <div className="modal" style={style}>
      <h4>{title}</h4>
      {children}
      <button onClick={onClose} className='cancel-edit-button'><IonIcon icon={closeOutline} /></button>
    </div>
  );
};

export default Modal;
