import { useCallback, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import api from '../../services/api';

const Register = ({ onRegisterSuccess }) => {
  const [formData, setFormData] = useState({
    username: '',
    firstname: '',
    middlename: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [isBlocked, setIsBlocked] = useState(false);
  const notify = (message, type = null, params = null) => type ? toast[type](message, params) : toast(message, params);

  useEffect(() => {
    setIsFormValid(Object.keys(formData).length === Object.keys(touchedFields).length
      && Object.values(fieldErrors).every((error) => error === ''));
  }, [fieldErrors, touchedFields, formData]);

  const validateField = useCallback((name, value) => {
    let error = '';
    if (!value.trim()) {
      error = 'Это поле обязательно для заполнения';
    } else {
      switch (name) {
        case 'username':
          if (value.length < 3) error = 'Логин должен содержать не менее 3 символов';
          break;
        case 'firstname':
          if (value.length < 2) error = 'Имя должно содержать не менее 2 символов';
          break;
        case 'surname':
          if (value.length < 2) error = 'Фамилия должна содержать не менее 2 символов';
          break;
        case 'email':
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(value)) error = 'Неверный формат электронной почты';
          break;
        case 'password':
          const passwordPattern = /^(?=.*[a-zA-Zа-яА-Я])(?=.*[A-ZА-Я])(?=.*[a-zа-я])(?=.*\d)(?=.*[@$!%*?&])[A-Za-zА-Яа-я\d@$!%*?&]{8,}$/;
          if (!passwordPattern.test(value)) {
            error = 'Пароль должен содержать не менее 8 символов, включая как минимум одну заглавную букву, одну строчную букву, одну цифру и один специальный символ';
          }
          if (formData.confirmPassword && value !== formData.confirmPassword) {
            setFieldErrors(prev => ({ ...prev, confirmPassword: 'Пароли не совпадают' }));
          } else {
            setFieldErrors(prev => ({ ...prev, confirmPassword: '' }));
          }
          break;
        case 'confirmPassword':
          if (value !== formData.password) error = 'Пароли не совпадают';
          break;
        default:
          break;
      }
    }
    setFieldErrors(prev => ({ ...prev, [name]: error }));
  }, [formData.confirmPassword, formData.password]);

  const handleInputChange = useCallback((target) => {
    const name = target.name;
    const value = target.value;
    if (!touchedFields[name])
      setTouchedFields((prev) => ({ ...prev, [name]: true }));
    setFormData(prev => ({ ...prev, [name]: value }));
    validateField(name, value);
  }, [touchedFields, validateField]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isBlocked) {
      setError('Too many login attempts. Please try again later.');
      return;
    }
    try {
      await api.post('/u/register', formData);
      notify("Вы успешно зарегистрировались", "success");
      onRegisterSuccess();
    } catch (err) {
      if (err.response && err.response.status === 429) {
        setIsBlocked(true);
        setError('Too many login attempts. Please try again later.');
        setTimeout(() => setIsBlocked(false), 15 * 60 * 1000);
      } else if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        console.log(err);
        setError('An error occurred during registration.');
      }
    }
  }, [formData, isBlocked, onRegisterSuccess]);

  return (
    <form onSubmit={handleSubmit}>
      <label className="auth-label">
        <span className="field-name">Логин</span>
        <input
          type="text"
          value={formData.username}
          name="username"
          onChange={(e) => handleInputChange(e.target)}
          autoComplete="username"
        />
        {touchedFields.username && fieldErrors.username && <span className="error-info">{fieldErrors.username}</span>}
      </label>
      <label className="auth-label">
        <span className="field-name">Имя</span>
        <input
          type="text"
          value={formData.firstname}
          name="firstname"
          onChange={(e) => handleInputChange(e.target)}
          autoComplete="given-name"
        />
        {touchedFields.firstname && fieldErrors.firstname && <span className="error-info">{fieldErrors.firstname}</span>}
      </label>
      <label className="auth-label">
        <span className="field-name">Отчество</span>
        <input
          type="text"
          value={formData.middlename}
          name="middlename"
          onChange={(e) => handleInputChange(e.target)}
          autoComplete="additional-name"
        />
        {touchedFields.middlename && fieldErrors.middlename && <span className="error-info">{fieldErrors.middlename}</span>}
      </label>
      <label className="auth-label">
        <span className="field-name">Фамилия</span>
        <input
          type="text"
          value={formData.surname}
          name="surname"
          onChange={(e) => handleInputChange(e.target)}
          autoComplete="family-name"
        />
        {touchedFields.surname && fieldErrors.surname && <span className="error-info">{fieldErrors.surname}</span>}
      </label>
      <label className="auth-label">
        <span className="field-name">Электронная почта</span>
        <input
          type="email"
          value={formData.email}
          name="email"
          onChange={(e) => handleInputChange(e.target)}
          autoComplete="email"
        />
        {touchedFields.email && fieldErrors.email && <span className="error-info">{fieldErrors.email}</span>}
      </label>
      <label className="auth-label">
        <span className="field-name">Пароль</span>
        <input
          type="password"
          value={formData.password}
          name="password"
          onChange={(e) => handleInputChange(e.target)}
          autoComplete={"new-password"}
        />
        {touchedFields.password && fieldErrors.password && <span className="error-info">{fieldErrors.password}</span>}
      </label>
      <label className="auth-label">
        <span className="field-name">Подтвердите пароль</span>
        <input
          type="password"
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={(e) => handleInputChange(e.target)}
          autoComplete="new-password"
        />
        {touchedFields.confirmPassword && fieldErrors.confirmPassword && <span className="error-info">{fieldErrors.confirmPassword}</span>}
      </label>
      {error && <p>{error}</p>}
      <button type="submit" disabled={!isFormValid}>Регистрация</button>
    </form>

  );
}

export default Register;
