import React from 'react';
import './WeekHeader.css';
import { IonIcon } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';

const WeekHeader = ({ week, weekIndex, weeks, onDeleteWeek, groups }) => {
  return (
    <tr>
      <td colSpan={groups.length + 1} className='week-header' >
        <div className='week-header-wrapper'>
          <span className='week-header-title'>Неделя {week.number}</span>
          {weekIndex === weeks.length - 1 && (
            <button onClick={() => onDeleteWeek(week)} className='week-delete'>
              <div className='week-delete-icon-wrapper'><IonIcon className='week-delete-icon' icon={trashOutline} /></div>
              <span className='week-delete-hint'>Удалить неделю</span>
            </button>
          )}
        </div>
      </td>
    </tr >
  );
};

export default WeekHeader;
