import React, { createContext, useState, useCallback, useRef } from 'react';

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Расписание загружается');
  const timerRef = useRef(null);

  const showLoader = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setLoading(true);
      timerRef.current = null;
    }, 1000);
  }, []);

  const hideLoader = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setLoading(false);
  }, []);

  return (
    <LoaderContext.Provider value={{ loading, loadingMessage, setLoadingMessage, showLoader, hideLoader }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
