import React, { useContext } from 'react';
import LoaderContext from '../../context/LoaderContext';
import './Loader.css';

const Loader = () => {
  const { loading, loadingMessage } = useContext(LoaderContext);

  if (!loading) return null;

  return (
    <div className="loader">
      <span className='loader-title'>{loadingMessage}</span>
      <img src={`${process.env.PUBLIC_URL}/loader_5ms.svg`} alt="Загрузка..." className="loader-svg" />
    </div>
  );
};

export default Loader;
