// src/pages/NotFound.js
import React from 'react';
import { IonIcon } from '@ionic/react';
import { lockClosedOutline } from 'ionicons/icons';
import '../assets/css/forbidden.scss';

const Forbidden = () => {
  return (
    <div className='forbidden'>
      <IonIcon className='forbidden-icon' icon={lockClosedOutline} />
      <h2 className='forbidden-title'>Доступ к этой странице запрещён</h2>
    </div>
  );
};

export default Forbidden;
