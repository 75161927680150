import React from 'react';
import ScheduleCell from './ScheduleCell';
import './DayRow.css';

const DayRow = ({
  day, weekIndex, dayIndex, groups, schedule, handleDropClass, handleRemoveClass,
  handleMoveClass, resizing, resizingRef, cursorCellRef, setResizing, mergeClass,
  cursorCell, setCursorCell, getLastIndex, getBoundaries, getMaxLessonsPerDay,
  teachers, locations, onUpdateClass, semester, maxWeeks, isEditing, setIsEditing
}) => {
  const maxLessons = getMaxLessonsPerDay(weekIndex, dayIndex);
  const cellKey = `${weekIndex}-${dayIndex}`;

  return (
    <>
      {Array.from({ length: maxLessons }).map((_, lessonIndex) => (
        <tr key={`${cellKey}-${lessonIndex}`}>
          {lessonIndex === 0 && <td rowSpan={maxLessons} className='row-day-name'>{day}</td>}
          {groups.map((group, groupIndex) => {
            const lastIndex = getLastIndex(cellKey, groupIndex, lessonIndex);
            return (
              <ScheduleCell
                key={`${cellKey}-${groupIndex}`}
                weekIndex={weekIndex}
                dayIndex={dayIndex}
                groupIndex={groupIndex}
                lessonIndex={lessonIndex}
                lastIndex={lastIndex}
                schedule={schedule[cellKey] && schedule[cellKey][groupIndex] && schedule[cellKey][groupIndex][lessonIndex]}
                onDropClass={handleDropClass}
                onRemoveClass={handleRemoveClass}
                onMoveClass={handleMoveClass}
                resizing={resizing}
                resizingRef={resizingRef}
                cursorCellRef={cursorCellRef}
                setResizing={setResizing}
                mergeClass={mergeClass}
                cursorCell={cursorCell}
                setCursorCell={setCursorCell}
                getBoundaries={getBoundaries}
                teachers={teachers}
                locations={locations}
                onUpdateClass={onUpdateClass}
                semester={semester}
                maxWeeks={maxWeeks}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            );
          })}
        </tr>
      ))}
    </>
  );
};

export default DayRow;
